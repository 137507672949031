<template>
  <v-card id="inicio">
    <ModalForm :visible="show" @close="show = false" :message="modalMsg" />
    <v-card-title class="text-center justify-center py-6">
      <h1>
        {{ title }}
      </h1>
    </v-card-title>
    <form v-on:submit.prevent="saveItem">

      <div class="form-group col-md-4">
        <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()"
          v-bind:disabled="wait">Cancelar</button>
      </div>

      <v-bottom-navigation bg-color="#E3F2FD" fixed>
        <v-btn icon @click="toGroup('#inicio')">
          <v-icon>mdi-file-document-outline</v-icon>
        </v-btn>
        <v-btn icon @click="toGroup('#archivos')">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <v-btn icon @click="toGroup('#seguimientos')">
          <v-icon>mdi-timeline-text-outline</v-icon>
        </v-btn>
        <v-btn icon @click="toGroup('#emails')" v-if="direccion == 'E'">
          <v-icon>mdi-email-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>

      <v-card id="datos">
        <v-card-text>

          <div class="text-right text-info">
            <label>(*) Campo obligatorio</label>
          </div>
          <div class="form-group" v-bind:style="{ display: showRelacion }">
            <p>
              <span v-if="$route.params.respuesta">Respuesta a: </span>
              <span v-else>Relacionado con: </span>
              <span style="color:red">{{ relacion.tcr_cdgo }}-{{ relacion.dcm_ano }}-{{ relacion.dcm_cdgo }}</span>
            </p>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label>Clase de Documento (*):</label>
              <select v-model="item.tcr_id" ref="tcr_id" class="form-control" autofocus required
                v-bind:disabled="!add || $route.params.respuesta || $route.params.search.dcm_archivado"
                v-on:change="setTpocor">
                <option v-for="tpocor in tpocors" v-bind:value="tpocor._id">
                  {{ tpocor.tcr_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label>Año (*):</label>
              <input type="number" class="form-control" v-model="item.dcm_ano" disabled="true">
            </div>
            <div class="form-group col-md-4">
              <label>Número consecutivo (*):</label>
              <input type="number" class="form-control" v-model="item.dcm_cdgo" disabled="true">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4" v-if="isMail">
              <label>Fecha de Radicación (*):</label>
              <input type="text" class="form-control" v-model="fcharad" disabled="true" required>
            </div>
            <div class="form-group col-md-4" v-if="isMail">
              <label class="text-primary">Fecha actual:</label>
              <input type="text" class="form-control text-primary" v-model="now" disabled="true">
            </div>
            <div class="form-group col-md-4" v-if="!add && allowModify && isMail">
              <label for=""> </label>
              <a href="#" v-on:click.prevent="showSello()" data-toggle="modal" data-target="#selloModal"><printer-icon
                  class="custom-class"></printer-icon> Sello</a>
              <createsello v-bind:sello="sello"></createsello>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4" v-if="isMail">
              <label>Tipo Documental (*):</label>
              <select v-model="item.tdo_id" class="form-control"
                v-bind:disabled="!allowModify || $route.params.search.dcm_archivado" required>
                <option v-for="tpodoc in tpodocs" v-bind:value="tpodoc._id">
                  {{ tpodoc.tdo_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label>Fecha Inicial (*):</label>
              <input type="date" class="form-control" v-model="fchaini" v-on:change="setFechaInicial"
                v-bind:disabled="!allowModify" required>
            </div>
            <div class="form-group col-md-4" v-if="!isMail">
              <label>Fecha Final:</label>
              <input type="date" class="form-control" v-model="fchafin" v-bind:disabled="!allowModify">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Tipo de Tabla (*):</label>
              <select v-model="item.tta_id" class="form-control" required disabled="true">
                <option v-for="tpotabla in tpotablas" v-bind:value="tpotabla._id">
                  {{ tpotabla.tta_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label>Versión (*):</label>
              <input type="number" class="form-control" v-model="item.tav_nmro" required disabled="true">
            </div>
          </div>
          <div class="form-group" v-if="!isMail">
            <label>Código:</label>
            <input type="text" class="form-control" v-bind:value="codigo_archivo" disabled="true">
          </div>
          <div class="form-group">
            <label>Dependencia (*):</label>
            <select v-model="item.dep_id" ref="dep_id" class="form-control" v-on:change="setDependencia"
              v-bind:disabled="!allowModify || isRelacion || $route.params.dep_id" required>
              <option v-for="dependencia in dependencias" v-bind:value="dependencia._id">
                {{ dependencia.dep_cdgo + ' - ' + dependencia.dep_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Serie/Subserie (*):</label>
            <select v-model="item.ser_id" ref="ser_id" class="form-control" v-on:change="setSerie"
              v-bind:disabled="!allowModify || isRelacion || $route.params.ser_id" required>
              <option v-for="serie in series" v-bind:value="serie._id">
                {{ serie.ser_cdgo + ' - ' + serie.ser_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6" v-if="isMail && (direccion == undefined || direccion == 'R')">
              <label>Tipo de entidad solicitante:</label>
              <select v-model="item.tso_id" ref="tso_id" class="form-control"
                v-bind:disabled="!allowModify || isRelacion">
                <option v-for="tposolic in tposolics" v-bind:value="tposolic._id">
                  {{ tposolic.tso_nmbre }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label>Entidad (opcional):</label>
            <i class="fas fa-question-circle"
              title="(Opcional) Puede seleccionar una de las entidades de la lista o agregar una nueva haciendo clic en Crear nueva entidad"></i>
            <!--
                    <select id="ent_id" v-model="item.ent_id" ref="ent_id" class="form-control" v-on:select="setEntidad" v-bind:disabled="!allowModify" v-bind:required="isMail&&!item.dcm_entidad">
                      <option v-for="entidad in entidades" v-bind:value="entidad.id">
                        {{ entidad.text }}
                      </option>
                    </select>
                    -->
            <!--
                    <select2 id="ent_id" v-model="item.ent_id" :options="entidades" :addOption="linkNewEntidad" v-on:select="setEntidad" v-bind:disabled="!allowModify" v-bind:required="isMail&&!item.dcm_entidad" />
                    -->
            <select2ajax id="ent_id" v-model="item.ent_id" :addOption="linkNewEntidad" :url="'/entidades/select'"
              v-on:select="setEntidad" v-bind:disabled="!allowModify" v-bind:required="isMail && !item.dcm_entidad" />
            <a href="#" v-on:click.prevent="eliminarEntidad" v-bind:disabled="!allowModify">
              <i class="fas fa-trash" style="font-size:18px;color:blue"></i>
            </a>
            <create-entidad v-on:add-entidad="setNewEntidad" v-bind:add="addEnt"
              v-bind:entidad="entidad"></create-entidad>
          </div>
          <div class="form-row">
            <div class="form-group col-md-8">
              <label v-if="isMail">Entidad / Detalles (*):</label>
              <label v-else>Entidad / Detalles:</label>
              <i class="fas fa-question-circle"
                title="Puede agregar más detalles para identificar el área o la persona encargada"></i>
              <textarea rows="2" class="form-control" v-model="item.dcm_entidad" v-bind:disabled="!allowModify"
                v-bind:required="isMail && !item.ent_id" />
            </div>
            <div class="form-group col-md-4" v-if="isMail">
              <label>Ciudad (*):</label>
              <select2ajax id="ciud_id" v-model="item.ciud_id" :url="'/ciudades/select'" v-on:select="setCiudad"
                v-bind:disabled="!allowModify" v-bind:required="isMail && !item.dcm_ciudad" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6" v-if="isMail">
              <label>Dirección:</label>
              <input type="text" class="form-control" v-model="item.dcm_direccion" v-bind:disabled="!allowModify">
            </div>
            <div class="form-group col-md-6" v-if="isMail">
              <v-combobox 
                v-model="selectedEmailAddresses"
                :items="emailAddresses" 
                :search-input.sync="searchEmailAddresses"
                :disabled="!allowModify" 
                label="Correo electrónico:"
                chips
                multiple
              >
                <template v-slot:selection="{ attrs, item, parent, selected, disabled }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    :disabled="disabled"
                    label
                    small
                  >
                    <span class="pr-2">
                      {{ item.text }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>                
                  </v-chip>
                </template>
              </v-combobox>

            </div>
          </div>
          <div class="form-group">
            <label>{{ isMail ? 'Asunto (*):' : 'Nombre del expediente (*):' }}</label>
            <textarea rows="3" class="form-control" v-model="item.dcm_asunto" v-bind:disabled="!allowModify" required />
          </div>
          <div class="form-group" v-if="isMail && !add && item.dcm_mensaje && (direccion == 'R' || !allowModify)">
            <label>Mensaje:</label>
            <div class="mensaje_pqrs">{{ item.dcm_mensaje }}</div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4" v-if="isMail">
              <label>Anexos:</label>
              <input type="text" class="form-control" v-model="item.dcm_anexos" v-bind:disabled="!allowModify">
            </div>
            <div class="form-group col-md-4">
              <label># Folios:</label>
              <input type="number" min="0" class="form-control" v-model="item.dcm_nflios" v-bind:disabled="!allowModify">
            </div>
            <div class="form-group col-md-4" v-if="!isMail">
              <label>Tomo:</label>
              <input type="text" class="form-control" v-model="item.dcm_tomo" v-bind:disabled="!allowModify">
            </div>
          </div>
          <div class="form-group" v-if="tramitadoOpcion">
            <label>Tramitado por:</label>
            <select v-model="item.dep_tramite_id" ref="dep_tramite_id" class="form-control"
              v-bind:disabled="!allowModify">
              <option v-for="dependencia in dependencias" v-bind:value="dependencia._id">
                {{ dependencia.dep_cdgo + ' - ' + dependencia.dep_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-row text-center" v-if="isMail && (direccion == undefined || direccion == 'R')">
            <div class="form-group col-md-3">
              <label>Tipo de solicitud:</label>
              <select v-model="item.tpq_id" ref="tpq_id" class="form-control" v-on:change="setTpopqrs"
                v-bind:disabled="!allowModify || $route.params.search.dcm_archivado">
                <option v-for="tpopqr in tpopqrs" v-bind:value="tpopqr._id">
                  {{ tpopqr.tpq_nmbre }}
                </option>
              </select>
              <a href="#" v-on:click.prevent="eliminarPqrs"
                v-bind:disabled="!allowModify || $route.params.search.dcm_archivado">
                <i class="fas fa-trash" style="font-size:18px;color:blue"></i>
              </a>
            </div>
            <div class="form-group col-md-3">
              <input type="checkbox" class="form-check-input" id="dcm_rspsta" v-model="item.dcm_rspsta"
                v-on:click="validarRespuesta" v-bind:disabled="!allowModify">
              <label class="form-check-label" for="dcm_rspsta">Necesita Respuesta</label>
            </div>
            &nbsp;
            <div class="form-group">
              <label>Tiempo de rspta (días):</label>
              <input type="number" min="0" class="form-control" v-model="item.dcm_tres" v-bind:disabled="!allowModify">
            </div>
            &nbsp;
            <div class="form-group">
              <label>Respuesta:</label>
              <input type="text" class="form-control" v-model="rspsta" v-bind:disabled="true">
            </div>
          </div>
          <div class="form-row" v-if="isMail && (direccion == undefined || direccion == 'R')">
            <div class="form-group col-md-6">
              <label>Medio preferido de respuesta:</label>
              <select v-model="item.mdr_id" ref="mdr_id" class="form-control"
                v-bind:disabled="!allowModify || isRelacion">
                <option v-for="medioresp in medioresps" v-bind:value="medioresp._id">
                  {{ medioresp.mdr_nmbre }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="!isMail">
            <label>Soportes:</label>
            <input type="text" class="form-control" v-model="item.dcm_soportes" v-bind:disabled="!allowModify">
          </div>
          <div class="form-row text-center">
            <div class="form-group col-md-6">
              <input type="checkbox" class="form-check-input" id="dcm_electronico" v-model="item.dcm_electronico"
                v-on:click="setArchivo" v-bind:disabled="!allowModify">
              <label for="dcm_electronico">Documento electrónico:</label>
            </div>
            <div class="form-group col-md-6">
              <input type="checkbox" class="form-check-input" id="dcm_archivado" v-model="item.dcm_archivado"
                v-on:click="setArchivo" v-bind:disabled="!allowModify || $route.params.search.dcm_archivado">
              <label for="dcm_archivado">Archivado:</label>
            </div>
          </div>
          <div class="form-group">
            <label>Tipo Archivo (*):</label>
            <select v-model="item.tpa_id" class="form-control" required
              v-bind:disabled="isMail || !allowModify || $route.params.search.dcm_archivado">
              <option v-for="tpoarch in tpoarchs" v-bind:value="tpoarch._id">
                {{ tpoarch.tpa_nmbre }}
              </option>
            </select>
          </div>
          <div class="row" v-if="archivo" v-bind:style="{ display: 'block' }">
            <hr>
            <h5>
              <p style="color:darkblue">Ubicación</p>
            </h5>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Sala (*):</label>
                <select v-model="item.sal_id" ref="sal_id" class="form-control"
                  v-bind:disabled="!allowModify || $route.params.search.dcm_archivado">
                  <option v-for="sala in salas" v-bind:value="sala._id">
                    {{ sala.sal_nmbre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Caja (*):</label>
                <input type="number" ref="caj_cdgo" class="form-control" v-model="item.caj_cdgo"
                  v-bind:disabled="!allowModify || $route.params.search.dcm_archivado">
              </div>
              <div class="form-group col-md-4">
                <label># Inventario (*):</label>
                <input type="number" ref="lgj_nmro" class="form-control" v-model="item.lgj_nmro"
                  v-bind:disabled="!allowModify || $route.params.search.dcm_archivado">
              </div>
            </div>
          </div>

          <hr>
          <div class="form-group">
            <label><b style="color:darkblue">INDICES</b></label>
            <div v-if="allowModify || direccion == 'R'">
              <div class="">
                <a href="#" v-on:click.prevent="newIndice()" data-toggle="modal"
                  data-target="#indiceModal"><plus-circle-icon class="custom-class"></plus-circle-icon>Agregar</a>
                <createindice v-on:add-indice="addIndice(indice)" v-bind:indicesButtonText="indicesButtonText"
                  v-bind:cancelButtonVisibility="cancelButtonVisibility" v-bind:indice="indice"></createindice>
              </div>
            </div>
            <div>
              <table class="table table-bordered">
                <tr>
                  <th>Índice</th>
                  <th>Valor</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr v-for="(i, index) in item.indices">
                  <td>{{ i.ind_nmbre }}</td>
                  <td>{{ i.ixd_valor }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="editIndice(index)" data-toggle="modal" data-target="#indiceModal"
                      v-if="allowModify" title="Editar índice"><edit-icon class="custom-class"></edit-icon></a>
                  </td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="deleteIndice(index)" v-if="allowModify || i.temp"
                      title="Eliminar índice"><delete-icon class="custom-class"></delete-icon></a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <hr>
          <div v-if="direccion == 'O'" class="form-group" id="_hojacontrol">
            <label><b style="color:darkblue">HOJA DE CONTROL</b></label>
            <div v-if="allowModify" class="form-group">
              <a href="#" v-on:click.prevent="newTipodoc()" class="col-md-6" data-toggle="modal"
                data-target="#tipoDocModal"><plus-circle-icon class="custom-class"></plus-circle-icon>Agregar</a>
              <a href="#" v-on:click.prevent="generateControlSheet_v2()" class="col-md-6"><i
                  class="fas fa-print fa-lg"></i></a>
              <create-tipo-doc v-on:add-tipodoc="addTipodoc(tipodoc)" v-bind:tipodoc="tipodoc"></create-tipo-doc>
            </div>
            <div>
              <table class="table table-bordered">
                <tr>
                  <th>Número</th>
                  <th>Fecha</th>
                  <th>Descripción del documento</th>
                  <th>Folio inicial</th>
                  <th>Folio final</th>
                  <th>Área responsable</th>
                  <th></th>
                </tr>
                <tr v-for="(i, index) in item.hojacontrol">
                  <td v-bind:class="{ tachado: i.delete }">{{ i.hjc_nmro }}</td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.hjc_fecha | formatDateUtc }}</td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.hjc_descripcion_doc }}</td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.hjc_folio_ini }}</td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.hjc_folio_fin }}</td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.hjc_area_responsable }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="editTipodoc(index)" v-if="allowModify" data-toggle="modal"
                      data-target="#tipoDocModal" title="Editar documento"><edit-icon
                        class="custom-class"></edit-icon></a>
                  </td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="deleteTipodoc(index)" v-if="i.temp || allowModify && !i.delete"
                      title="Eliminar documento"><delete-icon class="custom-class"></delete-icon></a>
                    <a href="#" v-on:click.prevent="addDeletedTipodoc(index)" v-if="allowModify && i.delete"
                      title="Agregar documento"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
                  </td>
                </tr>
              </table>
            </div>
          </div>

        </v-card-text>
      </v-card>
      <v-card id="archivos">
        <v-card-text>

          <div class="form-group" id="_adjuntos">
            <label><b style="color:darkblue">ARCHIVOS ADJUNTOS</b></label>
            <i class="fas fa-question-circle"
              title="Puede agregar archivos ya existentes en el dispositivo o también puede crear un pdf a partir de una plantilla. Guarde primero el documento para activar el botón de Crear archivo desde plantilla."></i>
            <!--
              <div class="custom-file" v-if="allowModify">
              -->
            <div class="form-group" v-if="direccion != 'O'">
              <div class="form-group">
                <input type="file" name="adjunto" id="files" ref="files" v-on:change="addFiles" multiple
                  class="" style="display:none">

                <a href="#" v-on:click.prevent="openFiles" class="col-md-6"
                  title="Agregar archivos existentes"><i class="fas fa-paperclip fa-lg"></i></a>

                <a href="#" v-if="(direccion == 'E' || direccion == 'O') && !add" v-on:click.prevent="newFileTemplate('')"
                  class="col-md-6" data-toggle="modal" data-target="#fileModal" title="Crear archivo desde plantilla">
                  <i class="far fa-file fa-lg"></i>
                </a>
                <create-file v-on:add-file="addFileTemplate(file)" v-bind:file="file"
                  v-bind:plantillas="plantillas"></create-file>
              </div>
            </div>
            <div class="form-group" v-if="direccion != 'O'">
              <div class="large-12 medium-12 small-12 cell">
                <table class="table table-bordered">
                  <tr>
                    <td><v-icon>mdi-filter</v-icon></td>
                    <td></td>
                    <td>
                      <input type="text" v-model="filenameFilter"
                        v-on:keyup="filterFiles({ 'file': filenameFilter, 'tag': tagnameFilter })" class="form-control"
                        placeholder="">
                      <v-icon v-if="filenameFilter" v-on:click="resetFiles" small>mdi-filter-remove</v-icon>
                    </td>
                    <td>
                      <input type="text" v-model="tagnameFilter"
                        v-on:keyup="filterFiles({ 'file': filenameFilter, 'tag': tagnameFilter })" class="form-control"
                        placeholder="">
                      <v-icon v-if="tagnameFilter" v-on:click="resetTags" small>mdi-filter-remove</v-icon>
                    </td>
                    <td v-if="!ocultar_fecha_adjuntos"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Nombre de Archivo</th>
                    <th>Etiqueta</th>
                    <th v-if="!ocultar_fecha_adjuntos">Agregado</th>
                    <th>Tamaño</th>
                    <th>Páginas</th>
                    <th>Usuario</th>
                    <th></th>
                  </tr>
                  <tr v-for="(i, index) in files">
                    <td v-bind:class="{ tachado: i.delete }">{{ index + 1 }}</td>
                    <td style="text-align:center">
                      <div v-if="i.uploaded === '1'" class="progress">
                        <div class="progress-bar" role="progressbar" :style.prop="percentageValue(i.percentage)"
                          :aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">Cargando</div>
                      </div>
                      <!--
                        <img v-if="i.uploaded === '1'" src="/images/loading.gif" />
                        -->
                      <span v-if="i.uploaded === '2'">Error</span>
                      <span v-if="i.uploaded === '0'">
                        <div v-if="isPdf(i.adj_nmbre)">
                          <a href="#" v-on:click.prevent="viewPdf(i.adj_nmbre, i.temp, index, i.adj_ocr)">
                            <i class="far fa-file-pdf" style="font-size:18px;color:red"></i>
                          </a>
                          <a href="#" v-if="activar_firmas && (direccion == 'E' || direccion == 'O') && !i.adj_firmado"
                            v-on:click.prevent="signPdf(i.adj_nmbre, i.temp, true, index)">
                            <i class="fas fa-pen-fancy" style="font-size:18px;color:blue" title="Firmar documento"></i>
                          </a>
                          <i v-if="(direccion == 'E' || direccion == 'O') && i.adj_firmado" class="fas fa-check"
                            style="font-size:16px;color:red" title="Firmado"></i>
                          <viewpdf v-bind:pdfFile="pdfFile"></viewpdf>
                          <img v-if="i.download" src="/images/loading.gif" />
                        </div>
                        <div v-if="!isPdf(i.adj_nmbre)">
                          <a href="#" v-on:click.prevent="downloadFile(i.adj_nmbre, i.temp, true, index)">
                            <download-icon class="custom-class"></download-icon>
                          </a>
                          <div v-if="i.download" class="progress">
                            <div class="progress-bar bg-success" role="progressbar"
                              :style.prop="percentageValue(i.downloadPercentage)" :aria-valuenow="0" aria-valuemin="0"
                              aria-valuemax="100">Descargando</div>
                          </div>
                        </div>
                      </span>
                    </td>
                    <td v-bind:class="{ tachado: i.delete }">{{ i.adj_nmbre }}</td>
                    <td v-bind:class="{ tachado: i.delete }">
                      <textarea rows="2" class="form-control" v-model="i.adj_etiqueta"
                        v-bind:disabled="!i.temp && !allowModify" style="{ font: 6px arial; }" />
                    </td>
                    <td v-bind:class="{ tachado: i.delete }" v-if="!ocultar_fecha_adjuntos">{{ i.adj_fecha | formatDate }}
                    </td>
                    <td v-bind:class="{ tachado: i.delete }">{{ formatBytes(i.adj_size) }}</td>
                    <td v-bind:class="{ tachado: i.delete }">{{ i.adj_pages || 0 }}</td>
                    <td v-bind:class="{ tachado: i.delete }">{{ i.usu_nmbre }}</td>
                    <td style="text-align:center">
                      <a href="#" v-on:click.prevent="deleteFile(index)" v-if="i.temp || allowModify && !i.delete"
                        title="Eliminar archivo adjunto"><delete-icon class="custom-class"></delete-icon></a>
                      <a href="#" v-on:click.prevent="addDeletedFile(index)" v-if="allowModify && i.delete"
                        title="Agregar archivo adjunto"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
                    </td>
                  </tr>
                </table>
              </div>              
            </div>
            <codigo-otp-firmese v-on:add-codigo="verificarCodigoOtpFirmese(codigoOtp)" v-bind:codigo="codigoOtp"
                v-on:cambiar-medio="cambiarMedioOtpFirmese(codigoOtp)">
            </codigo-otp-firmese>
            <div class="form-group">
              <create-file v-on:add-file="addFileTemplate(file, true)" v-bind:file="file"
                  v-bind:plantillas="plantillas"></create-file>
              <FileManager :doc="item" :direccion="direccion" :allowModify="allowModify" @newFileTemplate="newFileTemplate" @showModalForm="showModalForm" @signPdf="signPdf" />
            </div>
          </div>

        </v-card-text>
      </v-card>
      <v-card id="seguimientos">
        <v-card-text>

          <div class="form-group">
            <label><b style="color:darkblue">SEGUIMIENTO</b></label>
            <!--
              <div class="" v-if="validarUsuario()">
              -->
            <div class="">
              <a href="#" v-on:click.prevent="newSeguimiento()" data-toggle="modal"
                data-target="#seguimientoModal"><plus-circle-icon class="custom-class"></plus-circle-icon>Agregar</a>
              <createseguimiento v-on:add-seguimiento="addSeguimiento(seguimiento)"
                v-bind:seguimientosButtonText="seguimientosButtonText"
                v-bind:cancelButtonVisibility="cancelButtonVisibility" v-bind:seguimiento="seguimiento"
                v-bind:usuarios="usuarios_seg" v-bind:estados="estados_seg" v-bind:compartir="compartir">
              </createseguimiento>
            </div>
            <div>
              <table class="table table-bordered">
                <tr>
                  <!--
                    <th>Id</th>
                  -->
                  <th>Fecha</th>
                  <th>De</th>
                  <th>Para</th>
                  <th>Descripción</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
                <tr v-for="(i, index) in item.seguimientos" v-bind:style="{ 'background-color': i.color }">
                  <!--
                    <td>{{ i._id }}</td>
                  -->
                  <td v-bind:class="{ tachado: i.delete }">
                    {{ i.Fecha | formatDate }}
                    <div v-if="i.notificar">
                      <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
                    </div>
                  </td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.DeUsuarioNombre }}</td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.ParaUsuarioNombre }} <i class="fa fa-user-clock"
                      v-if="i.ult_seg"></i></td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.Descripcion }}</td>
                  <td v-bind:class="{ tachado: i.delete }">
                    {{ i.EstadoNombre }} <span v-if="i.FechaVencimiento"> hasta {{ i.FechaVencimiento | formatDateUtc
                    }}</span>
                  </td>
                  <!--
                    <td align="center">
                      <a href="#" v-on:click.prevent="editSeguimiento(index)" v-if="i.temp" data-toggle="modal" data-target="#seguimientoModal" title="Editar seguimiento"><edit-icon class="custom-class"></edit-icon></a>
                      <a href="#" v-on:click.prevent="editSeguimiento(index)" v-if="allowModify" data-toggle="modal" data-target="#seguimientoModal" title="Editar seguimiento"><edit-icon class="custom-class"></edit-icon></a>
                    </td>
                      -->
                  <td align="center">
                    <a href="#" v-on:click.prevent="deleteSeguimiento(index)" v-if="i.temp"
                      title="Eliminar seguimiento"><delete-icon class="custom-class"></delete-icon></a>
                    <!--
                      <a href="#" v-on:click.prevent="addDeletedSeguimiento(index)" v-if="i.delete" title="Agregar seguimiento"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
                      <a href="#" v-on:click.prevent="deleteSeguimiento(index)" v-if="i.temp||allowModify&&!i.delete" title="Eliminar seguimiento"><delete-icon class="custom-class"></delete-icon></a>
                      <a href="#" v-on:click.prevent="addDeletedSeguimiento(index)" v-if="allowModify&&i.delete" title="Agregar seguimiento"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
                      -->
                  </td>
                </tr>
              </table>
            </div>
          </div>

        </v-card-text>
      </v-card>
      <v-card id="proceso" v-if="activar_procesos && isMail && (direccion == undefined || direccion == 'R')">
        <v-card-text>

          <div class="form-group">
            <label><b style="color:darkblue">PROCESO MISIONAL</b></label>

            <div class="form-row">
              <div class="form-group col-md-6" v-if="isMail">
                <label>Proceso:</label>
                <select v-model="item.prc_id" class="form-control"
                  v-bind:disabled="!allowModify">
                  <option v-for="i in procesos" v-bind:value="i._id">
                    {{ i.prc_cdgo + " - " + i.prc_nmbre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Fecha primera actuación:</label>
                <input type="date" class="form-control" v-model="fcha_primera_actuacion"
                  v-bind:disabled="!allowModify">
              </div>
            </div>

          </div>

        </v-card-text>
      </v-card>
      <v-card id="emails" v-if="direccion == 'E'">
        <v-card-text>

          <div class="form-group">
            <label>
              <span>
                <b style="color:darkblue">CORREO ELECTRÓNICO</b>
              </span>
            </label>
            <div class="form-row">
              <div class="form-group col-md-12" v-if="isMail">
                <label>Para:</label>
                <input type="email" class="form-control" v-model="item.dcm_email" v-bind:disabled="!allowModify" multiple>
              </div>
            </div>
            <div class="form-group">
              <label>{{ isMail ? 'Asunto (*):' : 'Nombre del expediente (*):' }}</label>
              <textarea rows="3" class="form-control" v-model="item.dcm_asunto" v-bind:disabled="!allowModify" required />
            </div>
            <div class="form-group">
              <label>Archivos adjuntos</label>
              <table class="table-sm">
                <tr v-for="(i, index) in files">
                  <td style="text-align:center">
                    <span v-if="i.uploaded === '0'">
                      <div v-if="isPdf(i.adj_nmbre)">
                        <i class="far fa-file-pdf" style="font-size:18px;color:red"></i>
                        <i v-if="(direccion == 'E' || direccion == 'O') && i.adj_firmado" class="fas fa-check"
                          style="font-size:16px;color:red" title="Firmado"></i>
                      </div>
                      <div v-if="!isPdf(i.adj_nmbre)">
                        <download-icon class="custom-class"></download-icon>
                      </div>
                    </span>
                  </td>
                  <td v-bind:class="{ tachado: i.delete }">{{ i.adj_nmbre }}</td>
                </tr>
              </table>
            </div>
            <div class="form-group" v-if="isMail && direccion == 'E' && item.dcm_email && allowModify">
              <label>Mensaje:</label>
              <vue-editor id="dcm_mensaje" class="mensaje_editor" v-model="item.dcm_mensaje"
                :editorToolbar="customToolbar"></vue-editor>
            </div>

            <div class="form-row justify-content-around"
              v-if="!add && isMail && direccion == 'E' && item.dcm_email && allowModify" v-bind:disabled="!allowModify">
              <div class="form-group">
                <div id="send-email" class="m-2 p-2 border border-info rounded"
                  v-on:mouseover.prevent="changeClassMouseOver('send-email')"
                  v-on:mouseout.prevent="changeClassMouseOut('send-email')">
                  <div class="text-center">
                    <a href="#" v-on:click.prevent="emailButton">
                      <img src="/images/email.png" title="Enviar por correo electrónico">
                    </a>
                  </div>
                  <div>
                    <span class="small">Enviar por correo electrónico</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
                <label>
                  <span>
                    <b style="color:darkblue">Trazabilidad     </b>
                  </span>
                  <span>
                    <a href="#" v-on:click.prevent="syncEmails()">
                      <i class="fas fa-sync-alt" style="font-size:16px"></i>
                    </a>                
                  </span>                
                </label>
                <ul class="timeline">
                  <li v-for="(i, index) in item.emails">
                    <!-- email_class_estado -->
                    <span :class="email_class_estado(i.ema_estado)">{{ emailEstado(i.ema_estado) }} - </span>
                    <span class="text-primary small">{{ i.ema_fecha | formatDateTime }}</span>
                    <span class="text-primary small">{{ emial_response(i) }} </span>
                    <span v-if="i.ema_estado=='send'">                            
                      <a href="#" v-on:click.prevent="viewEmail(item.cmp_id, item._id, i)">
                        <i class="far fa-envelope text-warning" style="font-size:16px"></i>
                      </a>
                    </span>
                    <span v-if="i.ema_usu_id" class="text-primary small"> ({{ userSendEmail(i.ema_usu_id) }})</span>
                  </li>
                </ul>
                <view-email v-bind:emailFile="emailFile"></view-email>
              </div>
          </div>

        </v-card-text>
      </v-card>

      <br>
      <div class="form-group col-md-12">
        <div v-if="message">
          <p class="alert alert-warning" role="alert">{{ message }}</p>
        </div>
        <div class="row justify-content-around" style="text-align:center">
          <div class="col-4">
            <button type="submit" class="btn btn-primary" v-on:click="setClose(true)" v-bind:disabled="wait">{{ buttontext }}</button>
          </div>
          <div class="col-4">
            <button type="text" class="btn btn-success" v-on:click="setClose(false)"
              v-bind:disabled="wait">Guardar</button>
          </div>
          <div class="col-4">
            <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()"
              v-bind:disabled="wait">Cancelar</button>
          </div>
        </div>
        <div class="row justify-content-around" style="text-align:center"
          v-if="!add && isMail && direccion == 'R' && $store.state.reclamaciones">
          <div class="col-12">
            <button type="text" class="btn btn-success" v-on:click="reclamacion()" v-bind:disabled="wait">Datos de
              reclamación</button>
          </div>
        </div>
        <div class="row justify-content-around" style="text-align:center"
          v-if="!add && isMail && direccion == 'R' && $store.state.pqrs">
          <div class="col-12">
            <button type="text" class="btn btn-success" v-on:click.prevent="formatoPqrs()" v-bind:disabled="wait">Datos de
              Pqrs</button>
          </div>
          <div>
            <br>
          </div>
        </div>
        <br>
      </div>

    </form>
  </v-card>
</template>

<script>
  /*
  import { VIcon } from 'vuetify/lib';
  */

  import ModalForm from '../modal/ModalForm.vue';
  import { PlusCircleIcon, EditIcon, DeleteIcon, DownloadIcon, PrinterIcon } from 'vue-feather-icons';

  import Sello from './Sello.vue';
  import CreateIndice from './CreateIndice.vue';
  import CreateFile from './CreateFile.vue';
  import CreateSeguimiento from './CreateSeguimiento.vue';
  import CreateEntidad from './CreateEntidad.vue';
  import ViewPdf from './ViewPdf.vue';
  import CodigoOtpFirmese from './CodigoOtpFirmese.vue';
  import CreateTipoDoc from './CreateTipoDoc.vue';
  import ViewEmail from './ViewEmail.vue';

  import moment from 'moment';
  import { formatDate, formatDateUtc, formatDateTime } from './../../plugins/filters';

  //import Tiff from 'tiff.js';

  import { wait } from '../../utils';
  import { tiempoUtilizado } from '../../utils';

  //import Select2 from '../select2/Select2.vue';
  import select2ajax from '../select2/Select2Ajax.vue';

  import { VueEditor, Quill } from "vue2-editor";

  import FileManager from "./FileManager.vue"

  export default {
    props: ['doc'],
    components: {
      //VIcon,
      //Select2, 
      select2ajax,
      ModalForm,
      'createsello': Sello,
      'createindice': CreateIndice,
      CreateFile,
      ViewEmail,
      'createseguimiento': CreateSeguimiento,
      CreateEntidad,
      CreateTipoDoc,
      'viewpdf': ViewPdf,
      CodigoOtpFirmese,
      PlusCircleIcon, EditIcon, DeleteIcon, DownloadIcon, PrinterIcon,
      VueEditor,
      FileManager
    },
    data() {
      return {
        add: false, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        dependencias: [],
        series: [],
        tpoarchs: [],
        tpocors: [],
        tpodocs: [],
        tpotablas: [],
        tpopqrs: [],
        buttontext: '',
        fcharad: null,
        fchaini: null,
        fchafin: null,
        indice: {},
        indices: [],
        usuarios: [],
        estados: [],
        salas: [],
        file: {},
        files: [],
        adjuntos: new FormData(),
        filenameFilter: '',
        filesFilter: false,
        tagnameFilter: '',
        tagsFilter: false,
        filterF: {},
        addInd: true,
        editInd: -1,
        indicesButtonText: 'Agregar indice',
        addAdj: true,
        editAdj: -1,
        adjuntosButtonText: 'Agregar adjunto',
        seguimiento: {},
        seguimientos: [],
        addSeg: true,
        editSeg: -1,
        seguimientosButtonText: 'Agregar seguimiento',

        tipodoc: {},
        tipodocs: [],
        addTd: true,
        editTd: -1,
        tipodocsButtonText: 'Agregar seguimiento',

        showModal: false,
        isMail: true,
        isRelacion: false,
        direccion: null,
        relacion: {},
        url: '',
        wait: false,
        ready: false,
        sello: {},
        allowModify: false, //Permiso para modificar el documento
        imprimir: false, //Permiso para imprimir el documento
        modificar_otros: false, //Permiso para modificar el documento si lo hizo otro usuario
        pdfFile: {},
        search: {},
        rspsta: '',
        gettingItem: false,
        notificacion: [],
        tramitadoOpcion: false,
        mensaje_sello: '',
        dep_cdgo: '',
        ser_cdgo: '',
        codigo_archivo: '',
        usuarios_seg: [],
        estados_seg: [],
        show: false,
        pqrs_email: '',
        timestamp: 0,
        entidad: {},
        entidades: [],
        addEnt: true,
        seg: [],
        tposolics: [],
        medioresps: [],
        now: null,
        lastRoute: null,
        plantillas: [],
        modalMsg: '',
        customToolbar: [['bold', 'italic', 'underline'], [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }], [{ 'indent': '-1' }, { 'indent': '+1' }], [{ 'color': [] }],
        ['link']],
        sendEmailButton: false,
        ocultar_fecha_adjuntos: false,
        codigoOtp: {},
        activar_firmas: false,
        activar_procesos: false,
        dep_id_actual: null,
        esReclamacion: false,
        esPqrs: false,
        compartir: false,
        emailFile: {},
        pathNewTemplate: null,
        procesos: [],
        fcha_primera_actuacion: null,
        emailAddresses: [],
        searchEmailAddresses: null,
        selectedEmailAddresses: [],
        nonce: 1,
      }
    },
    filters: {
      formatDate, formatDateUtc, formatDateTime
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      },
      archivo() {
        if (this.item.dcm_archivado) {
          return true;
        } else {
          return false;
        }
      },
      showRelacion(){
        if (this.isRelacion) {
          return 'block'
        } else {
          return 'none'
        }
      },
      "filesColumns": function filesColumns() {
        if (this.files.length == 0) {
          return [];
        }
        return Object.keys(this.files[this.files.length - 1]);
      }
    },
    created: async function() {
      this.modalMsg = 'Por favor espere mientras se consultan los datos...';
      this.show = true;

      this.add = this.$route.params.add;
      this.search = this.$route.params.search;
      await this.getNow();
      await this.fetchTimeStamp();
      await this.getSettingFechaAdjuntos();
      await this.getCompaniaFirmas();
      await this.getCompaniaProcesos();

      //this.fetchEntidad();
      await this.fetchTpodoc();
      await this.fetchTpotabla();
      await this.fetchTpoarch();
      await this.fetchTpopqrs();
      await this.fetchIndice();
      await this.fetchTposolic();
      await this.fetchMedioresp();
      await this.fetchProcesos();

      if (this.add){
        this.title = 'Crear un Documento';
        this.buttontext = 'Guardar y cerrar';
        
        if (this.$route.params.search.dcm_archivado){
          this.isMail = false;
          this.item.dcm_archivado = true;
          this.item.tcr_id = this.$route.params.tcr_id;
          this.item.tdo_id = this.$route.params.tdo_id;
          this.item.sal_id = this.search.sal_id;
          this.item.tpa_id = this.search.tpa_id;
          this.item.caj_cdgo = this.search.caj_cdgo;
          this.item.lgj_nmro = this.search.lgj_nmro;
        }

        this.allowModify = true;
        this.item.indices = [];
        this.item.adjuntos = [];
        this.item.hojacontrol = [];
        this.item.seguimientos = [];
        await this.fetchUsuario();
        await this.fetchEstado();
        await this.fetchTpocorUsuario();
        await this.fetchUsuxsal();

        if (this.$route.params.search.dcm_archivado || this.$route.params.dcm_rlcion != null){
          await this.fetchDependencia();
          await this.fetchSerie();

          if (this.$route.params.tta_id) this.item.tta_id = this.$route.params.tta_id;
          if (this.$route.params.tav_nmro) this.item.tav_nmro = this.$route.params.tav_nmro;
          if (this.$route.params.dep_id) this.item.dep_id = this.$route.params.dep_id;
          if (this.$route.params.ser_id) this.item.ser_id = this.$route.params.ser_id;
        }

        if (this.$route.params.dcm_rlcion != null){
          //alert('Relación: ' + this.$route.params.dcm_rlcion);
          this.isRelacion = true;
          this.item.dcm_expediente = String(this.$route.params.dcm_expediente);
          this.item.dcm_rlcion = String(this.$route.params.dcm_rlcion);
          this.relacion.tcr_cdgo = this.$route.params.tcr_cdgo;
          this.relacion.dcm_ano = this.$route.params.dcm_ano;
          this.relacion.dcm_cdgo = this.$route.params.dcm_cdgo;
          if (this.$route.params.tdo_id) this.item.tdo_id = this.$route.params.tdo_id;

          if (this.$route.params.ent_id && this.$route.params.ent_id != 'null'){
            this.item.ent_id = this.$route.params.ent_id;
          }
          this.item.dcm_entidad = this.$route.params.dcm_entidad;
          this.item.dcm_ciudad = this.$route.params.dcm_ciudad;
          this.item.dcm_direccion = this.$route.params.dcm_direccion;
          //this.item.dcm_email = this.$route.params.dcm_email;
          if (this.$route.params?.dcm_email != null) {
            this.selectedEmailAddresses = this.$route.params?.dcm_email.split(/[ ,;|]/).map(i => ({ text: i }));
          }
          this.item.dcm_asunto = this.$route.params.dcm_asunto;

          if (this.$route.params.respuesta){
            this.item.tcr_id = this.$route.params.tcr_respuesta;
            this.item.tpa_id = this.$route.params.tpa_id;
            this.item.dcm_es_respuesta = true;

            if (this.item.dcm_email != '' && !this.$route.params.dcm_resp_email){
              this.item.dcm_inbox_email = true;
              this.item.dcm_asunto = 'RESPUESTA: ' + this.$route.params.dcm_asunto;
              var rad = this.relacion.tcr_cdgo + '-' + this.relacion.dcm_ano + '-' + this.relacion.dcm_cdgo;
              var link = window.location.origin + '/pqrs/find';
              
              this.item.dcm_mensaje = `<p style="font-size:18px"><b>` + this.$store.state.companydata.cmp_nmbre + `</b></p><p style="font-size:16px"><b>Respuesta a solicitud</b></p><br><p style="font-size:14px">Hola, ` + this.item.dcm_entidad + `</p><p style="font-size:14px">Su solicitud con el asunto <b>` + this.$route.params.dcm_asunto + `</b> y con el radicado <b>` + rad + `</b> ha sido respondida.</p><p style="font-size:14px">Adjunto a este mensaje encontrará los archivos anexos.</p></br><p style="font-size:14px">También puede hacer la consulta a través de la página web de la entidad o haciendo click en el siguiente enlace:</p><p><a href="` + link + `" target="_blank" rel="noopener noreferrer">Clic aquí para consultar</a></p>`;
            }

            if (this.$route.params.dcm_web && !this.$route.params.dcm_resp_email){
              let uri = '/entidades/email';
              this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'ent_id': this.$route.params.ent_id })
              .then(response => {
                this.pqrs_email = response.data;
                //debugger;
              })
              .catch(err => {
                alert('No se encontró el correo electrónico para responder la PQRS registrada en la web.');
              });
            }
          }
        }

        this.show = false;

      } else {
        this.title = 'Editar un Documento';
        this.buttontext = 'Guardar y cerrar';
        await this.fetchTpocor();
        await this.fetchDependencia();
        await this.fetchSerie();
        await this.fetchSala();
        this.gettingItem = true;

        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.usuarios = response.data;

          let uri = '/estados/' + String(this.$store.state.company);
          this.axios.get(uri).then((response) => {
            this.estados = response.data;
            
            this.getItem(this.$route.params.id, (err) => {
              if (err){
                alert('Error al consultar el documento');
              } else {
                this.dep_id_actual = this.item.dep_id;
              }
            });
          });
        });
      }
    },
    mounted: function () {
      feather.replace();
    },
    destroyed: async function () {

    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.lastRoute = from
      })
    },
    methods: {
      formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${Math.ceil(parseFloat((bytes / Math.pow(k, i))))} ${sizes[i]}`
      },
      setClose(close) {
        this.close = close;
        this.sendEmailButton = false;
        this.esReclamacion = false;
        this.esPqrs = false;
      },
      async updateSeguimientos(){
        try{
          if (this.notificacion != undefined) {
            if (this.notificacion.length > 0) {
              this.show = true;              
              let uri = '/docs/updatenuevoseg';
              let response = await this.axios.post(uri, { id: this.item._id, cambios: this.notificacion });
              this.show = false;
            }
          }
        } catch(err){
          //alert('Error al actualizar el estado del seguimiento.')
          this.show = false;
        }
      },
      async cancel() {
        try {
          //this.$emit('close-doc', doc);
          await this.updateSeguimientos();
          if (this.lastRoute.name == 'DetailActivity') {
            this.$router.replace({ name: this.lastRoute.name, params: this.lastRoute.params });
          } else {
            this.$router.replace({ name: 'DisplayDoc', params: { search: this.search } });
          }
        } catch(err){

        }
      },
      async saveItem(){
        var n = 0;
        for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].uploaded == '0') {
            n++;
          }
        }
        if (n < this.files.length) {
          alert('Espere por favor, no se han cargado todos los archivos adjuntos.');
          return;
        }
        //alert("Sala: " + this.item.sal_id);
        if (!this.item.dcm_electronico && this.item.dcm_archivado) {
          if (!this.item.sal_id) {
            alert('Selecciona la Sala');
            this.$refs.sal_id.focus();
            return true;
          }
          if (this.item.caj_cdgo == undefined) {
            alert('Seleccione la Caja');
            this.$refs.caj_cdgo.focus();
            return true;
          }
          if (!this.item.lgj_nmro == undefined) {
            alert('Escriba el # de inventario');
            this.$refs.lgj_nmro.focus();
            return true;
          }
        }

        /*
        var d = new Date(this.fchaini);
        if(d.getTimezoneOffset() > 0){
            d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
        }
        this.item.dcm_fchaini = d;
        */

        this.item.dcm_fchaini = this.fchaini;

        /*
        if (this.fchafin != null){
          d = new Date(this.fchafin);
          if(d.getTimezoneOffset() > 0){
              d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
          }
          this.item.dcm_fchafin = d;
        }
        */

        if (this.fchafin != null) {
          this.item.dcm_fchafin = this.fchafin;
        }

        if (this.fcha_primera_actuacion != null) {
          this.item.dcm_fcha_primera_actuacion = this.fcha_primera_actuacion;
        }

        if (this.item.ent_id == null) {
          this.item.ent_id = undefined;
        }

        this.seg = this.item.seguimientos;

        //alert(this.item.dcm_fchaini);

        this.wait = true;
        //$('#waitModal').modal('show');
        this.modalMsg = 'Por favor espere mientras se guardan los datos..';
        this.show = true;

        /********** GUARDADO *********/
        const start = async (add, data) => {
          /* THE END */
          const theend = async (add, id, callback) => {
            if (this.close) {
              await this.updateSeguimientos();
              this.wait = false;
              if (this?.lastRoute?.name == 'DetailActivity') {
                  this.$router.replace({ name: this?.lastRoute?.name, params: this?.lastRoute?.params });
              } else {
                if (this.esReclamacion) {
                  this.$router.replace({ name: "CreateFormatoReclamo", query: { dcm_id: this.item._id, search: this.search } });
                } else if (this.esPqrs) {
                  this.$router.replace({ name: "CreateFormatoPqrs", query: { dcm_id: this.item._id, search: this.search } });
                } else {
                  this.$router.replace({ name: 'DisplayDoc', params: { tcr_id: this.item.tcr_id, search: this.search } });
                }
              }
            } else {
              this.wait = false;
              if (add) {
                this.getItem(id, (err) => {
                  if (err) {
                    this.show = false;
                    this.message = 'Error al consultar el documento.';
                    return callback(true);
                  } else {
                    this.show = false;
                    this.message = 'Registro guardado';
                    return callback(false);
                  }
                });
              } else {
                this.show = false;
                return callback(false);
              }
            }
          }
          /* END THE END */

          /* EMAIL */
          const email = async (add, data) => {
            var id = data._id;

            if (!add) {
              this.item.adjuntos = data.adjuntos;
              this.item.hojacontrol = data.hojacontrol;
              console.log(data.adjuntos);
              console.log(data.hojacontrol);
              this.item.seguimientos = data.seguimientos;
              await this.sincronizarDetalles();
            }

            this.enviarNotificaciones(data);

            theend(add, id, (err) => {
              if (err) {
                alert('Error al consultar el documento.');
              } else {
                /** SE OPRIMIO BOTON DE EMAIL */
                if (this.sendEmailButton) {
                  /** FUNCION PARA BUSCAR EL NÚMERO DE RADICADO PARA EL EMAIL */
                  const searchRad = (callback) => {
                    var rad = '';
                    var action = '';

                    let uri = '/docs/es-respuesta';
                    this.axios.post(uri, { cmp_id: this.$store.state.company, '_id': this.item._id })
                      .then((response) => {
                        if (response.data) {
                          var tcr_cdgo = '';
                          for (var i = 0; i < this.tpocors.length; i++) {
                            if (response.data.tcr_id == this.tpocors[i]._id) {
                              tcr_cdgo = this.tpocors[i].tcr_cdgo;
                              i = this.tpocors.length;
                            }
                          }

                          rad = tcr_cdgo + '-' + response.data.dcm_ano + '-' + response.data.dcm_cdgo;
                          action = 'rspsta';
                          return callback(false, rad, action);
                        } else {
                          var tcr_cdgo = '';
                          for (let i = 0; i < this.tpocors.length; i++) {
                            if (this.item.tcr_id == this.tpocors[i]._id) {
                              tcr_cdgo = this.tpocors[i].tcr_cdgo;
                              i = this.tpocors.length;
                            }
                          }

                          rad = tcr_cdgo + '-' + this.item.dcm_ano + '-' + this.item.dcm_cdgo;
                          action = 'enviada';
                          return callback(false, rad, action);
                        }
                      })
                      .catch((err) => {
                        //alert('Error al consultar si el documento es una respuesta.')
                        var tcr_cdgo = '';
                        for (let i = 0; i < this.tpocors.length; i++) {
                          if (this.item.tcr_id == this.tpocors[i]._id) {
                            tcr_cdgo = this.tpocors[i].tcr_cdgo;
                            i = this.tpocors.length;
                          }
                        }

                        rad = tcr_cdgo + '-' + this.item.dcm_ano + '-' + this.item.dcm_cdgo;
                        action = 'enviada';
                        return callback(false, rad, action);
                      });
                  }

                  var id = this.item._id;
                  var host = window.location.origin;
                  this.pqrs_email = this.item.dcm_email;

                  searchRad(async (err, rad, action) => {
                    if (err) {
                      alert('No se ha encontrado el número de radicado para el correo electrónico.');
                    } else {
                      var nombre = '';
                      if (this.item.dcm_entidad != '') {
                        nombre = this.item.dcm_entidad;
                      }

                      var adjuntos = this.item.adjuntos.map(i => ({ path: './../repos/' + this.$store.state.company + '/sdocs/' + Math.trunc(parseInt(id.substring(0, 8), 16) / 1000000).toString() + '/' + id + '/' + i.adj_nmbre }));

                      this.modalMsg = 'Enviando al correo electrónico (' + this.pqrs_email + ')..';
                      this.show = true;

                      await this.sendEmail({ action, adjuntos, email: this.pqrs_email, host, id, nombre, rad });

                      let uri4 = '/docs/sync_emails';
                      this.axios.post(uri4, { id: id })
                        .then(response => {
                          this.item.emails = response.data.emails;
                          this.show = false;
                        })
                        .catch(err => {
                          alert('El sistema no ha podido sincronizar el estado de los correos electrónicos en este momento.');
                          this.show = false;
                        })
                    }
                  });
                }
                /** END ENVIAR EMAIL */

                this.message = 'Archivos guardados';
              }
            });
          }
          /* END EMAIL */

          /** START */
          if (this.item.adjuntos == undefined){
            await email(add, data);
          } else {
            // Save files
            let uri2 = '/api/savefiles/' + String(this.$store.state.company) + '/' + this.$store.state.user + '/' + String(data._id) + '/' + this.timestamp;
            await this.axios.post(uri2, this.item)
              .then(async (response2) => {
                await email(add, data);
              }).catch(err2 => {
                this.wait = false;
                this.message = '¡Error al guardar los archivos! ' + err2;
                //$('#waitModal').modal('hide');
                this.show = false;
              });
          }
        }
        /*********  END GUARDADO */

        if (this.add) {
          let uri = '/docs/add';
          this.item.cmp_id = String(this.$store.state.company);
          this.item.usu_id = String(this.$store.state.user);

          //alert(this.item.dcm_fcharad);
          await this.axios.post(uri, { 'item': this.item, 'respuesta': this.$route.params.respuesta })
            .then((response) => {
              this.add = false;
              var data = response.data;
              //toastr.success(response.data.item, 'Response');

              if (this.isRelacion) {
                start(true, data);
              } else if (!this.isMail) {
                start(true, data);
              } else {
                let uri = '/docs/autorelacionar';
                this.axios.post(uri, { 'cmp_id': this.$store.state.company, '_id': data._id, 'dcm_entidad': this.item.dcm_entidad, 'tta_id': this.item.tta_id, 'dep_id': this.item.dep_id, 'ser_id': this.item.ser_id })
                  .then((response) => {
                    start(true, data);
                  }).catch((err) => {
                    start(true, data);
                  });
              }
            })
            .catch((err) => {
              this.wait = false;
              this.message = '¡Error al crear el registro! ' + err;
              //$('#waitModal').modal('hide');
              this.show = false;
            });
        } else {
          if (this.dep_id_actual && (this.dep_id_actual != this.item.dep_id)) {
            const dep_actual = this.dependencias.find(d => d._id === this.dep_id_actual);
            console.log(dep_actual);

            var dep_actual_nombre = '';
            if (dep_actual) {
              dep_actual_nombre = dep_actual.dep_nmbre;
            }

            const dep_nueva = this.dependencias.find(d => d._id === this.item.dep_id);
            var dep_nueva_nombre = '';
            if (dep_nueva) {
              dep_nueva_nombre = dep_nueva.dep_nmbre;
            }

            var estado_id = this.estados[0]._id;

            this.item.seguimientos.push({ 
              Descripcion: 'CAMBIO DEPENDENCIA: ' + dep_actual_nombre + ' --> ' + dep_nueva_nombre, 
              DeUsuarioId: String(this.$store.state.user), 
              ParaUsuarioId: String(this.$store.state.user), 
              EstadoId: String(estado_id), 
              Nuevo: false,
              temp: true
            });                   
          }

          let p = {};
          p.item = this.item;
          p.allowModify = this.allowModify;
          p.user = this.$store.state.user;

          let uri = '/docs/update';
          this.axios.post(uri, p)
            .then((response) => {
              var data = response.data;

              this.dep_id_actual = this.item.dep_id;

              if (!this.isMail) {
                start(false, data);
              } else {
                let uri = '/docs/autorelacionar';
                this.axios.post(uri, { 'cmp_id': this.$store.state.company, '_id': data._id, 'dcm_entidad': this.item.dcm_entidad, 'tta_id': this.item.tta_id, 'dep_id': this.item.dep_id, 'ser_id': this.item.ser_id })
                  .then((response) => {
                    start(false, data);
                  }).catch((err) => {
                    start(false, data);
                  });
              }
            })
            .catch((err) => {
              this.wait = false;
              this.message = '¡Error al actualizar el registro! ' + err;
              //$('#waitModal').modal('hide');
              this.show = false;
            });
        }
      },
      async sendEmail({ id, action, email, rad, nombre, host, adjuntos }) {
        try {
          var dcm_mensaje = this.item.dcm_mensaje + `
            <br>
            <p style="font-size:11px;font-weight:bold;background-color:#ffe699">Para poder visualizar todo el contenido de este mensaje, por favor selecciona la opción "Mostrar contenido bloqueado". Esta acción permitirá que recibas toda la información que necesitas.</p>
            `;

          let uri3 = '/api/email';
          const response = await this.axios.post(uri3, { cmp_id: String(this.$store.state.company), id, action, email, rad, nombre, empresa: this.$store.state.companydata.cmp_nmbre, host, adjuntos, cc_email: this.$store.state.userdata.usuario_email, usu_id: this.$store.state.user, mensaje: dcm_mensaje, asunto: this.item.dcm_asunto })
          this.message = 'El mensaje ha sido enviado por correo electrónico.';
          return response.data
        } catch (error) {
          let e = error.response.data;
          this.message = 'Error al enviar el correo electrónico.';
          alert("Error al enviar el correo electrónico: " + JSON.stringify(e));
          return null
        }
      },
      async getItem(id, callback){
        this.modalMsg = 'Por favor espere mientras se consultan los datos...';
        this.show = true;

        let uri = '/docs/edit/' + id;
        await this.axios.get(uri)
          .then(async (response) => {
            this.item = response.data;
            this.fchaini = this.item.dcm_fchaini.toString().substring(0, 10);
            if (this.item.dcm_fchafin != null) {
              this.fchafin = this.item.dcm_fchafin.toString().substring(0, 10);
            }
            if (this.item.dcm_fcha_primera_actuacion != null) {
              this.fcha_primera_actuacion = this.item.dcm_fcha_primera_actuacion.toString().substring(0, 10);
            }
            this.fcharad = moment(this.item.dcm_fcharad).format('DD/MMM/YYYY hh:mm A');

            if (this.item?.dcm_email != null) {
              this.selectedEmailAddresses = this.item?.dcm_email.split(/[ ,;|]/).map(i => ({ text: i }));
            }

            if (this.item.ent_id) {
              await this.fetchEntidadItem(this.item.ent_id);
            }

            if (this.item.ciud_id) {
              await this.fetchCiudadItem(this.item.ciud_id);
            } else if (this.item.dcm_ciudad) {
              $('#ciud_id').append('<option>' + this.item.dcm_ciudad + '</option>');
            }

            for (let i = 0; i < this.tpocors.length; i++) {
              if (String(this.item.tcr_id) == String(this.tpocors[i]._id)) {
                this.isMail = this.tpocors[i].tcr_corr;
                this.direccion = this.tpocors[i].tcr_direccion;
                this.tramitadoOpcion = this.tpocors[i].tcr_tramitado;
                this.mensaje_sello = this.tpocors[i].tcr_mensaje_sello;
                i = this.tpocors.length;
              }
            }

            for (let i = 0; i < this.dependencias.length; i++) {
              if (this.item.dep_id == this.dependencias[i]._id) {
                this.dep_cdgo = this.dependencias[i].dep_cdgo;
                i = this.dependencias.length;
              }
            }

            for (let i = 0; i < this.series.length; i++) {
              if (this.item.ser_id == this.series[i]._id) {
                this.ser_cdgo = this.series[i].ser_cdgo;
                this.codigo_archivo = this.dep_cdgo + '-' + this.ser_cdgo;
                i = this.series.length;
              }
            }

            for (let i = 0; i < this.item.indices.length; i++) {
              for (let j = 0; j < this.indices.length; j++) {
                if (String(this.item.indices[i].ind_id) == String(this.indices[j]._id)) {
                  this.item.indices[i].ind_nmbre = this.indices[j].ind_nmbre;
                  j = this.indices.length;
                }
              }
            }

            await this.sincronizarDetalles();

            // Modificar documento no relacionado en tablas
            if (this.$route.params.allowModifyDoc) {
              //alert('MODIFICAR: ' + this.$route.params.allowModifyDoc);
              //this.allowModify = true;
              //this.imprimir = true;
              //this.modificar_otros = true;
              //this.fetchDependenciaUsuario();
              await this.fetchSerieUsuario();
            } else {
              this.allowModify = false;

              let uri = '/uxr/auth';
              await this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': String(this.$store.state.user), 'dep_id': this.item.dep_id, 'ser_id': this.item.ser_id, 'tcr_id': this.item.tcr_id })
                .then(async (response) => {
                  if (response.data) {
                    this.imprimir = response.data.uxr_imprmr;
                    this.modificar_otros = response.data.uxr_mdfcr_otros;
                    var allowTemp = false;
                    if (response.data.uxr_mdfcr) {
                      if (String(this.item.usu_id) == String(this.$store.state.user)) {
                        allowTemp = true;
                      } else {
                        if (response.data.uxr_mdfcr_otros) {
                          allowTemp = true;
                        }
                      }
                    }

                    if (allowTemp) {
                      //debugger;
                      this.allowModify = true;
                      await this.fetchSerieUsuario();
                      console.log(this.item);
                      /*
                      if (this.item.dcm_archivado){
                        // BUSCAR PERMISO POR SALA
                        let uri = '/uxs/auth';
                        this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': String(this.$store.state.user), 'sal_id': this.item.sal_id })
                          .then((response) => {
                            if (response.data.uxs_mdfcr){
                              this.allowModify = true;
                              this.fetchDependenciaUsuario();
                              this.fetchSerieUsuario();
                            } else {
                              this.allowModify = false;
                            }
                          }).catch((err) => {
                            this.allowModify = false;
                          })
                      } else {
                        this.allowModify = true;
                        this.fetchDependenciaUsuario();
                        this.fetchSerieUsuario();
                      }
                      */
                    }
                  } else {
                    if (String(this.item.usu_id) == String(this.$store.state.user)) {
                      this.allowModify = true;
                      this.isRelacion = true;
                    }
                  }
                })
                .catch((err) => {
                  this.allowModify = false;
                  this.imprimir = false;
                  this.modificar_otros = false;
                });
            }

            if (this.item.dcm_id_rs != undefined) {
              let uri = '/docs/respuesta';
              await this.axios.post(uri, { '_id': this.item.dcm_id_rs })
                .then((response) => {
                  var tcr_cdgo = '';
                  for (var i = 0; i < this.tpocors.length; i++) {
                    if (response.data.tcr_id == this.tpocors[i]._id) {
                      tcr_cdgo = this.tpocors[i].tcr_cdgo;
                      this.rspsta = tcr_cdgo + '-' + response.data.dcm_ano + '-' + response.data.dcm_cdgo;
                      i = this.tpocors.length;
                    }
                  }
                })
                .catch((err) => {

                });
            }

            this.gettingItem = false;
            this.show = false;
            return callback(false);
          })
          .catch(err => {
            this.show = false;
            return callback(true);
          });
      },
      async sincronizarDetalles() {
        this.files = this.item.adjuntos;

        for (let i = 0; i < this.files.length; i++) {
          this.files[i].temp = false;
          this.files[i].uploaded = '0';
          this.files[i].percentage = 0;
          this.files[i].download = false;
          this.files[i].downloadPercentage = 0;
          for (let j = 0; j < this.usuarios.length; j++) {
            if (this.files[i].usu_id == this.usuarios[j]._id) {
              this.files[i].usu_nmbre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
              //this.$set(this.files, i, this.files[i]);
              j = this.usuarios.length;
            }
          }
        }

        var list = this.item.seguimientos;
        list.sort(function (a, b) {
          if (a.Fecha > b.Fecha) { return 1; };
          if (a.Fecha < b.Fecha) { return -1; };
          return 0;
        });

        this.notificacion = [];
        for (let i = 0; i < list.length; i++) {
          if (this.$store.state.user == list[i].ParaUsuarioId && list[i].Nuevo) {
            list[i].notificar = true;
            this.notificacion.push(list[i]._id);
          } else {
            list[i].notificar = false;
          }

          let deUsuario = false;
          let paraUsuario = false;
          for (let j = 0; j < this.usuarios.length; j++) {
            if (String(list[i].DeUsuarioId) == String(this.usuarios[j]._id)) {
              list[i].DeUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
              deUsuario = true;
            }
            if (String(list[i].ParaUsuarioId) == String(this.usuarios[j]._id)) {
              list[i].ParaUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
              paraUsuario = true;
            }
            if (deUsuario && paraUsuario) {
              j = this.usuarios.length;
            }
          }

          for (let k = 0; k < this.estados.length; k++) {
            if (String(list[i].EstadoId) == String(this.estados[k]._id)) {
              list[i].EstadoNombre = this.estados[k].Nombre;
              k = this.estados.length;
            }
          }

          if (i == list.length - 1 && this.item.dcm_rspsta) {
            list[i].ult_seg = true;

            var dnl = [];

            var p = {};
            p.cmp_id = this.$store.state.company;

            var uri = '/dianols/total';
            await this.axios.post(uri, p)
              .then((response) => {
                dnl = response.data;

                // Calcular tiempo de respuesta
                var restante = parseInt(this.item.dcm_tres) - tiempoUtilizado(this.item.dcm_fcharad, new Date(), dnl);
                var now = new Date();
                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

                //if (resp.getTime() < today.getTime()){
                if (restante < 0) {
                  list[i].color = 'rgba(255, 0, 0, 0.3)'; //red
                } else if (restante == 0) {
                  list[i].color = 'rgba(255, 165, 0, 0.3)'; //orange
                } else {
                  list[i].color = 'rgba(60, 179, 113, 0.3)'; //green
                }
              }).catch((err) => {
                alert('Error al consultar los días no laborales. ' + err);
              });
          }
        }
        this.item.seguimientos = list;
      },
      enviarNotificaciones(data) {
        /** EMAIL NUEVOS SEGUIMIENTOS */
        var seg = this.seg;

        var tcr_cdgo = '';
        for (let i = 0; i < this.tpocors.length; i++) {
          if (this.item.tcr_id == this.tpocors[i]._id) {
            tcr_cdgo = this.tpocors[i].tcr_cdgo;
            i = this.tpocors.length;
          }
        }

        var rad = tcr_cdgo + '-' + data.dcm_ano + '-' + data.dcm_cdgo;
        var host = window.location.origin;

        for (let i = 0; i < seg.length; i++) {
          if (seg[i].temp) {
            for (let j = 0; j < this.item.seguimientos.length; j++) {
              if (seg[i].DeUsuarioId != seg[i].ParaUsuarioId && seg[i].DeUsuarioId == this.item.seguimientos[j].DeUsuarioId && seg[i].ParaUsuarioId == this.item.seguimientos[j].ParaUsuarioId && seg[i].Descripcion == this.item.seguimientos[j].Descripcion && seg[i].EstadoId == this.item.seguimientos[j].EstadoId){
                var email = '';
                for (let k = 0; k < this.usuarios.length; k++) {
                  if (seg[i].ParaUsuarioId == this.usuarios[k]._id) {
                    email = this.usuarios[k].usu_email;
                    k = this.usuarios.length;
                  }
                }

                if (email != '' && email != null && email != undefined) {
                  var p = {
                    cmp_id: String(this.$store.state.company),
                    action: 'sgmnt',
                    email: email,
                    rad: rad,
                    nombre: this.item.seguimientos[j].ParaUsuarioNombre,
                    empresa: this.$store.state.companydata.cmp_nmbre,
                    host: host,
                    Fecha: moment(this.item.seguimientos[j].Fecha).format('DD/MMM/YYYY hh:mm A'),
                    DeUsuarioNombre: this.item.seguimientos[j].DeUsuarioNombre,
                    ParaUsuarioNombre: this.item.seguimientos[j].ParaUsuarioNombre,
                    Descripcion: this.item.seguimientos[j].Descripcion,
                    EstadoNombre: this.item.seguimientos[j].EstadoNombre,
                    id: data._id
                  }

                  let uri = '/api/email';
                  this.axios.post(uri, p)
                    .then(response => { 
                      this.message = 'Notificación enviada.';
                    })
                    .catch(err => {
                      this.message = 'No se pudo enviar la notificación del seguimiento al correo electrónico del usuario.';
                      //alert(this.message);
                    });
                }

                j = this.item.seguimientos.length;
              }
            }
          }
        }
      },
      setTpocor(e) {
        //alert(e.target.value);
        var t = e.target.value;

        if (t) {
          let uri = '/tpocors/defaults';
          let p = {
            cmp_id: this.$store.state.company,
            tcr_id: t
          };
          this.axios.post(uri, p)
            .then(response => {
              var tc = response.data;
              console.log(JSON.stringify(tc));

              if (tc.tcr_rad_hini && tc.tcr_rad_hfin) {
                var tcr_nmbre = tc.tcr_nmbre;
                var hi = new Date(tc.tcr_rad_hini);
                var hf = new Date(tc.tcr_rad_hfin);

                var now = new Date(tc.now);
                var di = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hi.getHours(), hi.getMinutes(), 0);
                var df = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hf.getHours(), hf.getMinutes(), 0);

                if (now.getDay() == 6 || now.getDay() == 0 || di > now || now > df) {
                  let h = moment(response.data.now).format('dddd, DD [de] MMMM [de] YYYY, hh:mm A');
                  alert('La fecha y hora actual (' + h + ') está fuera del horario permitido para radicar ' + tcr_nmbre);
                  this.item.tcr_id = undefined;
                }
              }
              this.isMail = tc.tcr_corr;
              this.direccion = tc.tcr_direccion;
              this.tramitadoOpcion = tc.tcr_tramitado;
              this.mensaje_sello = tc.tcr_mensaje_sello;
              //this.item.tpa_id = null;
              if (this.isMail) {
                this.item.tpa_id = tc.tpa_id;
              } else {
                this.item.tdo_id = tc.tdo_id;
              }
            })
            .catch(err => {
              alert(err.data.msg);
            });
        }
      },
      setFechaInicial(e) {
        if (!this.gettingItem) {
          var fecha = e.target.value;
          //alert('Fecha: ' + fecha);

          if (this.isRelacion) {
            if (this.$route.params.usuarioSegResponde) {
              let uri2 = '/tablas/deps/respuesta';
              this.axios.post(uri2, { 'cmp_id': String(this.$store.state.company), 'tta_id': this.$route.params.tta_id, 'tav_nmro': this.$route.params.tav_nmro, 'dep_id': this.$route.params.dep_id })
                .then((response2) => {
                  this.dependencias = response2.data;
                });
            }
          } else {
            let uri = '/tabvers/version';
            this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'fecha': fecha })
              .then((response) => {
                //alert(response.data.tav_nmro);
                //this.$set(this.item, 'tta_id', response.data.tta_id);
                //this.$set(this.item, 'tav_nmro', response.data.tav_nmro);
                this.item.tta_id = response.data.tta_id;
                this.item.tav_nmro = response.data.tav_nmro;

                let uri2 = '/tablas/deps';
                this.axios.post(uri2, { 'cmp_id': String(this.$store.state.company), 'usu_id': this.$store.state.user, 'tcr_id': this.item.tcr_id, 'tta_id': response.data.tta_id, 'tav_nmro': response.data.tav_nmro })
                  .then((response2) => {
                    var list = response2.data;
                    list.sort(function (a, b) {
                      if (a.dep_cdgo > b.dep_cdgo) { return 1; };
                      if (a.dep_cdgo < b.dep_cdgo) { return -1; };
                      return 0;
                    });
                    this.dependencias = list;
                  });
              })
              .catch((err) => {
                alert('No está definida la versión de tabla para la fecha señalada. Consulte con el administrador del sistema.');
              });
          }
        } else {
          let uri2 = '/tablas/deps';
          this.axios.post(uri2, { 'cmp_id': String(this.$store.state.company), 'usu_id': this.$store.state.user, 'tcr_id': this.item.tcr_id, 'tta_id': this.item.tta_id, 'tav_nmro': this.item.tav_nmro })
            .then((response2) => {
              var list = response2.data;
              list.sort(function (a, b) {
                if (a.dep_cdgo > b.dep_cdgo) { return 1; };
                if (a.dep_cdgo < b.dep_cdgo) { return -1; };
                return 0;
              });
              this.dependencias = list;
            });
        }
      },
      setDependencia(e) {
        var dep = e.target.value;

        for (var i = 0; i < this.dependencias.length; i++) {
          if (dep == this.dependencias[i]._id) {
            this.dep_cdgo = this.dependencias[i].dep_cdgo;
            i = this.dependencias.length;
          }
        }

        var uri = "/tablas/series";
        this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': String(this.$store.state.user), 'tcr_id': this.item.tcr_id, 'tta_id': this.item.tta_id, 'tav_nmro': this.item.tav_nmro, 'dep_id': dep })
          .then((response) => {
            var list = response.data;
            list.sort(function (a, b) {
              if (a.ser_cdgo > b.ser_cdgo) { return 1; };
              if (a.ser_cdgo < b.ser_cdgo) { return -1; };
              return 0;
            });
            this.series = list;
          })
          .catch((err) => {
            alert('No existen series relacionadas con esta dependencia.')
          });
      },
      setSerie(e) {
        if (!this.gettingItem) {
          var ser = e.target.value;

          for (var i = 0; i < this.series.length; i++) {
            if (ser == this.series[i]._id) {
              this.item.dcm_tres = this.series[i].ser_tres;
              this.ser_cdgo = this.series[i].ser_cdgo;
              i = this.series.length;
            }
          }
        }
      },
      setArchivo() {
        if (!this.item.dcm_archivado) {
          this.item.sal_id = undefined;
          this.item.caj_cdgo = undefined;
          this.item.lgj_nmro = undefined;
        }
      },
      async fetchTpocorUsuario() {
        var ponerVariables = () => {
          for (let i = 0; i < this.tpocors.length; i++) {
            if (String(this.item.tcr_id) == String(this.tpocors[i]._id)) {
              this.isMail = this.tpocors[i].tcr_corr;
              this.direccion = this.tpocors[i].tcr_direccion;
              this.tramitadoOpcion = this.tpocors[i].tcr_tramitado;
              this.mensaje_sello = this.tpocors[i].tcr_mensaje_sello;
              i = this.tpocors.length;
            }
          }
        }

        if (this.$route.params.usuarioSegResponde) {
          let uri = '/tpocors/respuesta';
          this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'tcr_id': String(this.$route.params.tcr_respuesta) })
            .then((response) => {
              this.tpocors = response.data;
              ponerVariables();
            });
        } else {
          let uri = '/tpocors/uxr';
          this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': String(this.$store.state.user) })
            .then((response) => {
              var list = response.data;
              list.sort(function (a, b) {
                if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
                if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
                return 0;
              });
              this.tpocors = list;
              ponerVariables();
            });
        }
      },
      async fetchTpocor() {
        let uri = '/tpocors/' + this.$store.state.company;
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
            if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
            return 0;
          });
          this.tpocors = list;

          for (let i = 0; i < this.tpocors.length; i++) {
            if (String(this.item.tcr_id) == String(this.tpocors[i]._id)) {
              this.isMail = this.tpocors[i].tcr_corr;
              this.direccion = this.tpocors[i].tcr_direccion;
              this.tramitadoOpcion = this.tpocors[i].tcr_tramitado;
              this.mensaje_sello = this.tpocors[i].tcr_mensaje_sello;
              i = this.tpocors.length;
            }
          }

        });
      },
      async fetchTpodoc() {
        let uri = '/tpodocs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.tdo_nmbre > b.tdo_nmbre) { return 1; };
            if (a.tdo_nmbre < b.tdo_nmbre) { return -1; };
            return 0;
          });
          this.tpodocs = list;
        });
      },
      async fetchTpotabla() {
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpotablas = response.data;
        });
      },
      async fetchTpoarch() {
        let uri = '/tpoarchs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.tpa_nmbre > b.tpa_nmbre) { return 1; };
            if (a.tpa_nmbre < b.tpa_nmbre) { return -1; };
            return 0;
          });
          this.tpoarchs = list;
        });
      },
      async fetchTpopqrs() {
        let uri = '/tpopqrs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpopqrs = response.data;
        });
      },
      async fetchDependencia() {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.dep_cdgo > b.dep_cdgo) { return 1; };
            if (a.dep_cdgo < b.dep_cdgo) { return -1; };
            return 0;
          });
          this.dependencias = list;
        });
      },
      fetchDependenciaUsuario() {
        let uri = '/tablas/deps';
        this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': this.$store.state.user, 'tcr_id': this.item.tcr_id, 'tta_id': this.item.tta_id, 'tav_nmro': this.item.tav_nmro })
          .then((response) => {
            var list = response.data;
            list.sort(function (a, b) {
              if (a.dep_cdgo > b.dep_cdgo) { return 1; };
              if (a.dep_cdgo < b.dep_cdgo) { return -1; };
              return 0;
            });
            this.dependencias = list;
          })
      },
      async fetchSerie() {
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.ser_cdgo > b.ser_cdgo) { return 1; };
            if (a.ser_cdgo < b.ser_cdgo) { return -1; };
            return 0;
          });
          this.series = list;
        });
      },
      async fetchSerieUsuario() {
        var uri = "/tablas/series";
        this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': String(this.$store.state.user), 'tcr_id': this.item.tcr_id, 'tta_id': this.item.tta_id, 'tav_nmro': this.item.tav_nmro, 'dep_id': this.item.dep_id })
          .then((response) => {
            var list = response.data;
            list.sort(function (a, b) {
              if (a.ser_cdgo > b.ser_cdgo) { return 1; };
              if (a.ser_cdgo < b.ser_cdgo) { return -1; };
              return 0;
            });
            this.series = list;
          })
          .catch((err) => {
            alert('No existen series relacionadas con esta dependencia.')
          });
      },
      async fetchIndice() {
        let uri = '/indices/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.indices = response.data;
        });
      },
      async fetchEstado() {
        let uri = '/estados/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.estados = response.data;
        });
      },
      async fetchSala() {
        let uri = '/salas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.sal_nmbre > b.sal_nmbre) { return 1; };
            if (a.sal_nmbre < b.sal_nmbre) { return -1; };
            return 0;
          });
          this.salas = list;
        });
      },
      async fetchUsuxsal() {
        let uri = '/uxs/salas';
        this.axios.post(uri, { cmp_id: this.$store.state.company, usu_id: this.$store.state.user, uxs_mdfcr: true }).then((response) => {
          this.salas = response.data;
        });
      },
      async fetchUsuario() {
        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.usuarios = response.data;

          if (this.item.seguimientos != undefined) {
            for (let i = 0; i < this.item.seguimientos.length; i++) {
              let deUsuario = false;
              let paraUsuario = false;
              for (let j = 0; j < this.usuarios.length; j++) {
                if (String(this.item.seguimientos[i].DeUsuarioId) == String(this.usuarios[j]._id)) {
                  this.item.seguimientos[i].DeUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
                  deUsuario = true;
                }
                if (String(this.item.seguimientos[i].ParaUsuarioId) == String(this.usuarios[j]._id)) {
                  this.item.seguimientos[i].ParaUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
                  paraUsuario = true;
                }
                if (deUsuario && paraUsuario) {
                  j = this.usuarios.length;
                }
              }
            }
          }

          for (let i = 0; i < this.files.length; i++) {
            for (let j = 0; j < this.usuarios.length; j++) {
              if (this.files[i].usu_id == this.usuarios[j]._id) {
                this.files[i].usu_nmbre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
                //this.$set(this.files, i, this.files[i]);
                j = this.usuarios.length;
              }
            }
          }

        });
      },
      fetchUsuariosSeguimiento(addSeg) {
        var p = {};
        p.compania = this.$store.state.company;
        p.activos = addSeg;

        let uri = '/usuarios/seguimiento';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.usu_nmbre.toLowerCase() > b.usu_nmbre.toLowerCase()) { return 1; };
            if (a.usu_nmbre.toLowerCase() < b.usu_nmbre.toLowerCase()) { return -1; };
            return 0;
          });
          this.usuarios_seg = list;
        });
      },
      fetchEstadosSeguimiento() {
        if (this.item.tcr_id) {
          let uri = '/estados/seguimiento';
          this.axios.post(uri, { cmp_id: String(this.$store.state.company), allowModify: this.allowModify, tcr_id: this.item.tcr_id })
            .then((response) => {
              var list = response.data;
              /*
              list.sort(function (a, b) {
                if (a.Nombre > b.Nombre) { return 1; };
                if (a.Nombre < b.Nombre) { return -1; };
                return 0;
              });
              */
              this.estados_seg = list;
            });
        }
      },
      showSello() {
        var tcr_cdgo = '';
        for (let i = 0; i < this.tpocors.length; i++) {
          if (this.item.tcr_id == this.tpocors[i]._id) {
            tcr_cdgo = this.tpocors[i].tcr_cdgo;
            i = this.tpocors.length;
          }
        }

        this.$set(this.sello, 'cmp_logo', String(this.$store.state.companydata.cmp_logo));
        this.$set(this.sello, 'cmp_nmbre', String(this.$store.state.companydata.cmp_nmbre));
        //this.$set(this.sello, 'cmp_id', String(this.$store.state.company));
        this.$set(this.sello, 'tcr_nmbre', this.$refs.tcr_id.options[this.$refs.tcr_id.selectedIndex].text);
        this.$set(this.sello, 'tcr_cdgo', tcr_cdgo);
        this.$set(this.sello, 'dcm_ano', this.item.dcm_ano);
        this.$set(this.sello, 'dcm_cdgo', this.item.dcm_cdgo);
        this.$set(this.sello, 'dcm_fcharad', moment(this.item.dcm_fcharad).format('DD/MMM/YYYY hh:mm A'));
        //this.$set(this.sello, 'dep_nmbre', this.$refs.dep_id.options[this.$refs.dep_id.selectedIndex].text);
        this.$set(this.sello, 'dep_nmbre', this.dependencias.find(i => i._id === this.item.dep_id).dep_nmbre);
        //this.$set(this.sello, 'ser_nmbre', this.$refs.ser_id.options[this.$refs.ser_id.selectedIndex].text);
        this.$set(this.sello, 'ser_nmbre', this.series.find(i => i._id === this.item.ser_id).ser_nmbre);
        if (this.item.dcm_anexos) {
          this.$set(this.sello, 'dcm_anexos', this.item.dcm_anexos);
        } else {
          this.$set(this.sello, 'dcm_anexos', '');
        }
        if (this.item.dep_tramite_id) {
          this.$set(this.sello, 'dep_tramite_nmbre', this.$refs.dep_tramite_id.options[this.$refs.dep_tramite_id.selectedIndex].text);
        } else {
          this.$set(this.sello, 'dep_tramite_nmbre', '');
        }
        if (this.item.dcm_nflios) {
          this.$set(this.sello, 'dcm_nflios', this.item.dcm_nflios);
        } else {
          this.$set(this.sello, 'dcm_nflios', '');
        }
        if (this.mensaje_sello) {
          this.$set(this.sello, 'recibido', this.mensaje_sello);
        } else {
          this.$set(this.sello, 'recibido', '');
        }

      },
      newIndice() {
        this.indicesButtonText = 'Agregar';
        this.indice = {};
        this.addInd = true;
      },
      addIndice(i) {
        //alert(indice);
        if (i.ind_id == null) {
          alert('Seleccione el índice');
          return;
        }
        if (i.ixd_valor == null) {
          alert('Escriba el valor del índice');
          return;
        }

        for (var j = 0; j < this.indices.length; j++) {
          if (String(i.ind_id) == String(this.indices[j]._id)) {
            i.ind_nmbre = this.indices[j].ind_nmbre;
            j = this.indices.length;
          }
        }

        if (this.addInd) {
          this.item.indices.push({
            ind_id: i.ind_id,
            ind_nmbre: i.ind_nmbre,
            ixd_valor: i.ixd_valor,
            temp: true
          });
        } else {
          this.item.indices[this.editInd].ind_id = i.ind_id;
          //this.item.indices[this.editInd].ind_cdgo = i.ind_cdgo;
          this.item.indices[this.editInd].ind_nmbre = i.ind_nmbre;
          this.item.indices[this.editInd].ixd_valor = i.ixd_valor;
          this.indicesButtonText = 'Agregar';
        }
        this.indice = {};
        //this.indice.ind_cdgo = '';
        //this.indice.ixd_valor = '';
        //this.$refs.ind_cdgo.focus();
        this.addInd = true;
        this.showModal = false;
      },
      editIndice(i) {
        this.indice = { ind_id: this.item.indices[i].ind_id, ixd_valor: this.item.indices[i].ixd_valor };
        console.log(this.indice);
        this.addInd = false;
        this.editInd = i;
        this.indicesButtonText = 'Guardar cambios';
      },
      deleteIndice(i) {
        const response = confirm('Está seguro que desea eliminar?');
        if (response) {
          this.item.indices.splice(i, 1);
        }
      },
      async fetchTimeStamp() {
        let uri = '/api/timestamp';
        this.axios.post(uri)
          .then((response) => {
            this.timestamp = response.data.timestamp;
          })
          .catch((err) => {
            alert('No se pudo crear el identificador temporal. Consulte con el administrador.');
          });
      },
      async getSettingFechaAdjuntos() {
        var p = { cmp_id: String(this.$store.state.company) };
        let uri = '/settings/ocultar_fecha_adjuntos';
        this.axios.post(uri, p)
          .then((response) => {
            this.ocultar_fecha_adjuntos = response.data.set_ocultar_fecha_adjuntos;
          })
          .catch((err) => {
            this.ocultar_fecha_adjuntos = false;
            //alert('Error al consultar la opción de fecha de adjuntos.');
          });
      },
      async getCompaniaFirmas() {
        var cmp_id = String(this.$store.state.company);
        var user_id = String(this.$store.state.user);
        console.log(cmp_id);
        console.log(user_id);
        //debugger;
        var p = { cmp_id, user_id };
        let uri = '/cmps/activar-firmas';
        this.axios.post(uri, p)
          .then((response) => {
            this.activar_firmas = response.data.cmp_activar_firmas;
          })
          .catch((err) => {
            this.activar_firmas = false;
            //alert('Error al consultar la opción de fecha de adjuntos.');
          });
      },
      async getCompaniaProcesos() {
        var cmp_id = String(this.$store.state.company);
        var p = { cmp_id };
        let uri = '/cmps/activar-procesos';
        this.axios.post(uri, p)
          .then((response) => {
            this.activar_procesos = response.data.cmp_activar_procesos;
          })
          .catch((err) => {
            this.activar_procesos = false;
            //alert('Error al consultar la opción de fecha de adjuntos.');
          });
      },
      openFiles() {
        document.querySelector('input[type="file"]').click();
      },
      async addFiles(e) {
        //alert('Agregando archivos');
        var f = e.target.files;
        var data;
        var n;
        var m; //Indice de respuesta
        var version; //Versión de archivo
        var ext = '';
        var name = '';
        var path = String(this.$store.state.company) + '/temp/' + this.$store.state.user + '/' + this.timestamp;

        if (f) {
          this.ready = false;
          this.resetFilter();

          var c = this.files.length; // Número de archivos iniciales

          for (var i = 0; i < f.length; i++) {
            version = 0;
            name = f[i].name;
            ext = f[i].name.substring(f[i].name.lastIndexOf('.'));
            for (var j = 0; j < this.files.length; j++) {
              if (name == this.files[j].adj_nmbre) {
                version++;
                name = f[i].name.substring(0, f[i].name.lastIndexOf('.')) + ' (' + version + ')' + ext;
              }
            }
            this.files.push({});
            n = this.files.length - 1;
            this.files[n]['adj_nmro'] = n + 1;
            this.files[n]['adj_nmbre'] = name;
            this.files[n]['usu_id'] = this.$store.state.user;
            this.files[n]['usu_nmbre'] = this.$store.state.userdata.name;
            this.files[n]['uploaded'] = '1';
            this.files[n]['temp'] = true;
            this.files[n]['percentage'] = 0;
            this.files[n]['download'] = false;
            this.files[n]['downloadPercentage'] = 0;
            this.$set(this.files, n, this.files[n]);

            //n++;
          }

          const chunkSize = 1024 * 1024;

          const uploadProgress = (file, id, start) => (progressEvent) => {
            this.files[id].percentage = parseInt(Math.round((start + chunkSize * progressEvent.loaded / progressEvent.total) * 100 / file.size));
            this.$set(this.files, id, this.files[id]);
          }

          const sendChunk = async (file, id, chunkIndex, totalChunk) => {
            const start = chunkIndex * chunkSize;
            const end = (chunkIndex + 1) * chunkSize;

            const formData = new FormData();
            const blobSlice = file.slice(start, end);
            name = this.files[id].adj_nmbre;

            formData.append('file', blobSlice, encodeURIComponent(name));
            formData.append('index', id);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunk', totalChunk);
            return await this.axios.post('/api/upload/' + path,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: uploadProgress(file, id, start).bind(this)
              }
            );                  
          }

          var i = -1;
          for await (const file of f) {
            const totalChunk = Math.ceil(file.size/chunkSize);
            i++;
            let id = c + i;

            for (let chunkIndex = 0; chunkIndex < totalChunk; chunkIndex++) {
              try {
                var response = await sendChunk(file, id, chunkIndex, totalChunk);
                if (response) {
                  const last = (chunkIndex == totalChunk - 1);
                  if (last) {
                    let { index, pages, size } = await response.data;
                    this.files[index]['uploaded'] = '0';
                    this.files[index]['adj_pages'] = pages
                    this.files[index]['adj_size'] = size
                    this.$set(this.files, index, this.files[index]);
                  }
                }
                            
              } catch(err) {
                let { index } = await err.data;
                this.files[index]['uploaded'] = '2';
                this.$set(this.files, index, this.files[index]);
              }
            }

          }

          this.item.adjuntos = this.files;
          this.ready = true;
          e.target.value = '';
        }
      },
      deleteFile(i) {
        const response = confirm('Está seguro que desea eliminar?');
        if (response) {
          let index = i;
          if (this.filenameFilter || this.tagnameFilter) {
            for (let k = 0; k < this.item.adjuntos.length; k++) {
              if (this.files[i].adj_nmbre == this.item.adjuntos[k].adj_nmbre) {
                index = k;
                k = this.item.adjuntos.length;
                if (this.files[i].temp) {
                  this.files.splice(i, 1);
                } else {
                  this.files[i].delete = true;
                  this.$set(this.files, i, this.files[i]);
                }
              }
            }
          }
          if (this.item.adjuntos[index].temp) {
            this.item.adjuntos.splice(index, 1);
          } else {
            this.item.adjuntos[index].delete = true;
            this.$set(this.item.adjuntos, index, this.item.adjuntos[index]);
          }
        }
      },
      addDeletedFile(i) {
        let index = i;
        if (this.filenameFilter || this.tagnameFilter) {
          for (let k = 0; k < this.item.adjuntos.length; k++) {
            if (this.files[i].adj_nmbre == this.item.adjuntos[k].adj_nmbre) {
              index = k;
              k = this.item.adjuntos.length;
              //this.files.splice(i,1);
              this.files[i].delete = false;
              this.$set(this.files, i, this.files[i]);
            }
          }
        }
        //this.item.adjuntos.splice(index,1);
        this.item.adjuntos[index].delete = false;
        this.$set(this.item.adjuntos, index, this.item.adjuntos[index]);
      },
      downloadFile(adj_nmbre, temp, download, id) {
        this.files[id].download = true;
        this.files[id].downloadPercentage = 0;
        this.$set(this.files, id, this.files[id]);

        const downloadProgress = (id) => (progressEvent) => {
          this.files[id].downloadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          this.$set(this.files, id, this.files[id]);
        }

        this.axios({
          url: '/api/download',
          method: 'POST',
          data: { 'cmp_id': this.$store.state.company, '_id': this.item._id, 'usu_cdgo': this.$store.state.user, 'adj_nmbre': adj_nmbre, 'temp': temp, 'download': download, 'ts': this.timestamp },
          responseType: 'blob',
          onDownloadProgress: downloadProgress(id).bind(this)
        })
          //.then(wait(5000))
          .then((response) => {
            this.files[id].download = false;
            this.$set(this.files, id, this.files[id]);
            console.log(encodeURIComponent(adj_nmbre));
            const url = window.URL.createObjectURL(new File([response.data], encodeURIComponent(adj_nmbre), { type: 'application/octet-stream' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', adj_nmbre);
            //link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            this.files[id].download = false;
            this.$set(this.files, id, this.files[id]);
          });
      },
      filterFiles(f) {
        //alert(f.tag);
        this.filterF = f;
        if (f.file == '' && f.tag == '') {
          this.resetFilter();
        } else if (f.file != '' && f.tag != '') {
          this.files = this.item.adjuntos.filter(file => file.adj_nmbre.toLowerCase().indexOf(f.file.toLowerCase()) > -1 && file.adj_etiqueta.toLowerCase().indexOf(f.tag.toLowerCase()) > -1);
        } else if (f.file != '') {
          this.files = this.item.adjuntos.filter(file => file.adj_nmbre.toLowerCase().indexOf(f.file.toLowerCase()) > -1);
        } else if (f.tag != '') {
          this.files = this.item.adjuntos.filter(file => file.adj_etiqueta != null && file.adj_etiqueta.toLowerCase().indexOf(f.tag.toLowerCase()) > -1);
        }

      },
      resetFilter() {
        this.files = this.item.adjuntos;
      },
      resetFiles() {
        this.filenameFilter = '';
        this.filterFiles({ 'file': this.filenameFilter, 'tag': this.tagnameFilter })
      },
      resetTags() {
        this.tagnameFilter = '';
        this.filterFiles({ 'file': this.filenameFilter, 'tag': this.tagnameFilter })
      },
      newFileTemplate(path) {
        this.pathNewTemplate = path

        this.file = {
          encabezado: '',
          contenido: '',
          piepagina: ''
        };

        this.fetchPlantilla();
      },
      fetchPlantilla() {
        let uri = '/plantillas/' + String(this.$store.state.company);
        this.axios.get(uri)
          .then(response => {
            this.plantillas = response.data;
            $('#fileModal').modal('show');
          });
      },
      addFileTemplate(file, filemanager) {
        var n;
        var m; //Indice de respuesta
        var version; //Versión de archivo
        var ext = '';
        var name = '';
        var path = String(this.$store.state.company) + '/temp/' + this.$store.state.user + '/' + this.timestamp;

        if (file){
          this.ready = false;
          this.modalMsg = 'Por favor espere mientras se genera el archivo adjunto..';
          this.show = true;
          this.resetFilter();

          var c = this.files.length; // Número de archivos iniciales
          if (file.name){
            name = file.name;
          } else {
            name = 'Documento.pdf';
          }

          version = 0;
          ext = name.substring(name.lastIndexOf('.'));
          for (var j = 0; j < this.files.length; j++){
            if (name == this.files[j].adj_nmbre){
              version++;
              name = name.substring(0, name.lastIndexOf('.')) + ' (' + version + ')' + ext;
            }
          }
          file.name = name;

          this.sello = {};

          if (this.direccion == 'E'){
            this.showSello();
          }

          let uri2 = '/api/templatepdf';
          var params =  {
            cmp_id: String(this.$store.state.company),
            sello: this.sello,
            file: file,
            usu_id: String(this.$store.state.user),
            ts: this.timestamp,
            filemanager,
            item: this.item,
            path: this.pathNewTemplate
          };

          console.log(JSON.stringify(params))
          debugger
          
          this.axios.post(uri2, params)
          .then((response2) => {
            if (!filemanager){
              var { pages, size } = response2.data

              // Save files
              let uri3 = '/api/savefiles/' + this.item.cmp_id + '/' + this.item.usu_id + '/' + this.item._id + '/' + this.timestamp;
              this.axios.post(uri3, this.item)
              //.then(wait(3000)) // DEV ONLY: wait for 1.5s
              .then((response3) => {
                this.files.push({});
                n = this.files.length-1;
                this.files[n]['adj_nmro'] = n + 1;
                this.files[n]['adj_nmbre'] = name;
                this.files[n]['usu_id'] = this.$store.state.user;
                this.files[n]['usu_nmbre'] = this.$store.state.userdata.name;
                this.files[n]['uploaded'] = '0';
                this.files[n]['temp'] = true;
                this.files[n]['percentage'] = 0;
                this.files[n]['download'] = false;
                this.files[n]['downloadPercentage'] = 0;
                this.files[n]['adj_pages'] = pages;
                this.files[n]['adj_size'] = size;

                this.$set(this.files, n, this.files[n]);
          

                this.item.adjuntos = this.files;
                this.ready = true;
                this.show = false;
              }).catch(err3 => {
                this.ready = true;              
                this.show = false;
                this.message = '¡Error al guardar los archivos! ' + err3;
              });          

            } else {
              this.ready = true;
              this.show = false;
              $("#filemanager_tb_refresh").click()
            }
          })
          .catch((err2) => {
            this.ready = true;              
            this.show = false;
            this.message = '¡Error al crear el pdf! ' + err2;
          });          
        }
      },      
      addFileManagerTemplate(file) {
        var n;
        var m; //Indice de respuesta
        var version; //Versión de archivo
        var ext = '';
        var name = '';
        var path = String(this.$store.state.company) + '/temp/' + this.$store.state.user + '/' + this.timestamp;

        if (file) {
          this.ready = false;
          this.modalMsg = 'Por favor espere mientras se genera el archivo adjunto..';
          this.show = true;
          this.resetFilter();

          var c = this.files.length; // Número de archivos iniciales
          if (file.name) {
            name = file.name;
          } else {
            name = 'Documento.pdf';
          }

          version = 0;
          ext = name.substring(name.lastIndexOf('.'));
          for (var j = 0; j < this.files.length; j++) {
            if (name == this.files[j].adj_nmbre) {
              version++;
              name = name.substring(0, name.lastIndexOf('.')) + ' (' + version + ')' + ext;
            }
          }
          file.name = name;

          this.sello = {};

          if (this.direccion == 'E') {
            this.showSello();
          }

          //debugger;
          let uri2 = '/api/templatepdf';
          this.axios.post(uri2, {
            cmp_id: String(this.$store.state.company),
            sello: this.sello,
            file: file,
            usu_id: String(this.$store.state.user),
            ts: this.timestamp,
            filemanager: true,
            item: this.item,
            path: this.pathNewTemplate
          })
            .then((response2) => {
              this.ready = true;
              this.show = false;
              $("#filemanager_tb_refresh").click()
            })
            .catch((err2) => {
              this.ready = true;
              this.show = false;
              this.message = '¡Error al crear el pdf! ' + err2;
            });
        }
      },
      newSeguimiento() {
        this.seguimientosButtonText = 'Agregar';
        this.seguimiento = {};
        this.seguimiento.DeUsuarioId = String(this.$store.state.user);
        this.addSeg = true;
        this.fetchUsuariosSeguimiento(this.addSeg);
        this.fetchEstadosSeguimiento();
        this.compartir = false;
      },
      addSeguimiento(i) {
        //alert(indice);
        if (i.Descripcion == null) {
          alert('Escriba la Descripción del seguimiento');
          return;
        }
        if (i.DeUsuarioId == null) {
          alert('Escriba el Usuario origen');
          return;
        }
        if (i.ParaUsuarioId == null) {
          alert('Escriba el Usuario destino');
          return;
        }
        if (i.EstadoId == null) {
          alert('Escriba el Estado del seguimiento');
          return;
        }

        for (var j = 0; j < this.usuarios.length; j++) {
          if (String(i.DeUsuarioId) == String(this.usuarios[j]._id)) {
            i.DeUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
            j = this.usuarios.length;
          }
        }

        for (var j = 0; j < this.usuarios.length; j++) {
          if (String(i.ParaUsuarioId) == String(this.usuarios[j]._id)) {
            i.ParaUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
            j = this.usuarios.length;
          }
        }

        for (var j = 0; j < this.estados.length; j++) {
          if (String(i.EstadoId) == String(this.estados[j]._id)) {
            i.EstadoNombre = this.estados[j].Nombre;
            j = this.estados.length;
          }
        }

        //alert('add seguimiento');

        if (this.addSeg) {
          this.item.seguimientos.push({
            Descripcion: i.Descripcion,
            DeUsuarioId: String(i.DeUsuarioId),
            DeUsuarioNombre: i.DeUsuarioNombre,
            ParaUsuarioId: String(i.ParaUsuarioId),
            ParaUsuarioNombre: i.ParaUsuarioNombre,
            EstadoId: String(i.EstadoId),
            EstadoNombre: i.EstadoNombre,
            FechaVencimiento: i.FechaVencimiento,
            temp: true
          });
        } else {
          //this.item.seguimientos[this.editSeg]._id = i._id;
          //this.item.seguimientos[this.editSeg].Fecha = i.Fecha;
          this.item.seguimientos[this.editSeg].Descripcion = i.Descripcion;
          this.item.seguimientos[this.editSeg].DeUsuarioId = i.DeUsuarioId;
          this.item.seguimientos[this.editSeg].DeUsuarioNombre = i.DeUsuarioNombre;
          this.item.seguimientos[this.editSeg].ParaUsuarioId = i.ParaUsuarioId;
          this.item.seguimientos[this.editSeg].ParaUsuarioNombre = i.ParaUsuarioNombre;
          this.item.seguimientos[this.editSeg].EstadoId = i.EstadoId;
          this.item.seguimientos[this.editSeg].EstadoNombre = i.EstadoNombre;
          this.item.seguimientos[this.editSeg].FechaVencimiento = i.FechaVencimiento;

          this.seguimientosButtonText = 'Agregar';
        }

        this.seguimiento = {};
        this.addSeg = true;
        this.showModal = false;
      },
      editSeguimiento(i) {
        this.seguimiento = { Fecha: this.item.seguimientos[i].Fecha, Descripcion: this.item.seguimientos[i].Descripcion, DeUsuarioId: this.item.seguimientos[i].DeUsuarioId, ParaUsuarioId: this.item.seguimientos[i].ParaUsuarioId, EstadoId: this.item.seguimientos[i].EstadoId };
        console.log(this.seguimiento);
        this.addSeg = false;
        this.editSeg = i;
        this.seguimientosButtonText = 'Guardar cambios';
        this.fetchUsuariosSeguimiento(this.addSeg);
        this.fetchEstadosSeguimiento();
      },
      deleteSeguimiento(i) {
        const response = confirm('Está seguro que desea eliminar?');
        if (response) {
          if (this.item.seguimientos[i].temp) {
            this.item.seguimientos.splice(i, 1);
          } else {
            this.item.seguimientos[i].delete = true;
            this.$set(this.item.seguimientos, i, this.item.seguimientos[i]);
          }
        }
      },
      addDeletedSeguimiento(i) {
        //this.item.seguimientos.splice(index,1);
        this.item.seguimientos[i].delete = false;
        this.$set(this.item.seguimientos, i, this.item.seguimientos[i]);
      },
      linkNewEntidad() {
        return '<a href="#" class="new-item" data-toggle="modal" data-target="#entidadModal" style="padding: 6px;height: 20px;display: inline-table;">+ Crear nueva entidad</a>';
        //return '<span class="new-item">+ Crear nuevo item</span>';
      },
      linkEditEntidad() {
        return '<a href="#" v-on:click.prevent="close" data-toggle="modal" data-target="#entidadModal" style="padding: 6px;height: 20px;display: inline-table;">* Editar item</a>';
      },
      searchEntidad() {
        return {
          url: '/entidades/select',
          data: function (params) {
            var query = {
              cmd_id: this.$store.state.company,
              key: params.term
            }

            return query;
          }
        };
      },
      setEntidad(i) {
        let uri = '/entidades/edit/' + String(i.id);
        this.axios.get(uri)
          .then((response) => {
            var ent = response.data;
            this.$set(this.item, 'dcm_entidad', ent.ent_nmbre);
            //this.$set(this.item, 'dcm_ciudad', ent.ent_ciudad);
            this.$set(this.item, 'dcm_direccion', ent.ent_direccion);
            //this.$set(this.item, 'dcm_email', ent.ent_email);
            if (ent?.ent_email) {
              this.selectedEmailAddresses = ent?.ent_email.split(/[ ,;|]/).map(i => ({ text: i }));
            }
          })
          .catch((err) => {
            alert('No se ha encontrado la entidad');
          });
      },
      setNewEntidad(i) {
        var newOption = new Option(this.entidad.ent_nmbre + ' (' + this.entidad.ent_numero + ')', i._id, false, false);
        $('#ent_id').append(newOption).trigger('change');
        this.item.ent_id = i._id;
        this.entidad = {};
        this.setEntidad({ id: i._id });
      },
      closeEntidad() {
        //alert('Cerrar entidad');
        $('#ent_id').select2('close');
      },
      setCiudad(i) {
        let uri = '/ciudades/edit/' + String(i.id);
        this.axios.get(uri)
          .then((response) => {
            var ciudad = response.data;
            this.$set(this.item, 'dcm_ciudad', ciudad.ciud_desc_mcp + ' (' + ciudad.ciud_desc_dep + ')');
          })
          .catch((err) => {
            alert('No se ha encontrado la ciudad');
          });
      },
      pdfUrl(path, filename) {
        this.axios({
          url: '/api/files/download',
          method: 'POST',
          data: { 'path': path, 'filename': filename },
          responseType: 'blob'
        }).then((response) => {
          this.url = window.URL.createObjectURL(new Blob([response.data]));
          alert('aaaa');
        }).catch((err) => {
          this.url = '';
          alert('No se encontró el archivo.');
        });
      },
      isPdf(adj_nmbre) {
        if (adj_nmbre) {
          var ext = adj_nmbre.substring(adj_nmbre.lastIndexOf('.'));
          if (ext.toLowerCase() == '.pdf') {
            return true;
          }
          return false;
        } else {
          return false;
        }
      },
      async viewPdf(adj_nmbre, temp, id, adj_ocr) {
        try {
          this.modalMsg = 'Por favor espere mientras se muestra el archivo..';
          this.show = true;

          this.pdfFile = {};
          let path = '/';
          var data = {       
              _id: this.item._id,
              cmp_id: this.$store.state.company,
              path: path,
              name: encodeURIComponent(adj_nmbre)
          }

          if (temp) {
            data = {       
              ...data,
              user: this.$store.state.user,
              timestamp: this.timestamp,
              temp: temp
            }
          }

          let base_url = process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";

          var xocr = false; //Existe Ocr
          if (adj_ocr?.length) {
            xocr = true;
          }
        
          const response = await this.axios({
            url: `${base_url}/filesystemprovider/pass`,
            method: 'POST',
            data,
            headers: {
              'Authorization': sessionStorage.getItem('jwtToken')
            },
          });

          const token = response?.data?.tk;
          var url = `${base_url}/filesystemprovider/view-file?tk=${encodeURIComponent(token)}`;

          this.pdfFile = {
            url: url,
            adj_nmbre: adj_nmbre,
            xocr: xocr,
            adj_ocr: adj_ocr,
            ocr: false
          };

          $('#pdfModal').modal('show');
          this.show = false;
        } catch(err) {
          this.message = 'Error al mostrar el archivo.';
          this.show = false;
        }
      },
      signPdf(adj_nmbre, temp, download, id) {
        /** SELECCIONAR EL PROVEEDOR DEFAULT */
        /*** AUTENTICACIÓN */
        var p = {};
        p.cmp_id = String(this.$store.state.company);
        p.user_id = this.$store.state.user;
        let uri = '/firmas/select';
        this.modalMsg = 'Firmando el documento..';
        this.show = true;
        this.axios.post(uri, p)
          .then(response => {
            var access_token = response.data.access_token;
            var idUsuarioC = response.data.idUsuarioC;
            /** DISPONIBILIDAD DE FIRMAS */
            var p = {};
            var config = { headers: { 'Authorization': 'Bearer ' + access_token } };
            let uri = 'https://www.firme.se:9999/firma/api/v1/registro/usuario/servicio?codigo=' + idUsuarioC;
            this.axios.post(uri, p, config)
              .then((response) => {
                //alert(JSON.stringify(response.data));
                var cantidadFirmas = parseInt(response.data.data.cantidadFirmas);
                if (cantidadFirmas > 0) {
                  /************ SUBIR ARCHIVO *************/
                  const downloadProgress = (id, temp) => (progressEvent) => {
                    if (temp){
                      this.files[id].downloadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                      this.$set(this.files, id, this.files[id]);
                    }
                  }

                  var params = {
                    url: '/api/download',
                    method: 'POST',
                    data: { 'cmp_id': this.$store.state.company, '_id': this.item._id, 'usu_cdgo': this.$store.state.user, 'adj_nmbre': adj_nmbre, 'temp': temp, 'download': download, 'ts': this.timestamp },
                    responseType: 'blob'
                  };

                  if (id > 0) {
                    params.onDownloadProgress = downloadProgress(id, temp).bind(this);
                  }

                  this.axios(params)
                    //.then(wait(5000))
                    .then((response) => {
                      const fileReaderInstance = new FileReader();
                      fileReaderInstance.readAsDataURL(response.data);
                      fileReaderInstance.onload = () => {
                        var archivo64 = fileReaderInstance.result.split("base64,")[1];
                        //console.log(archivo64);      
                        var name = adj_nmbre.substring(adj_nmbre.lastIndexOf('/') + 1);
                        console.log('NAME: ' + name);

                        var data = [{
                          'nombreArchivo': name,
                          'cantidadFirmas': 1,
                          'idUsuario': idUsuarioC,
                          'archivo64': archivo64
                        }];
                        var config = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token } };
                        let uri = 'https://www.firme.se:9999/firma/api/v1/firma/manager/subir-multiple/b64';
                        this.axios.post(uri, data, config)
                        .then(response => {
                          if (response?.data?.codigo === '000') {
                            var archivo = response.data.data?.find(i => i.nombreArchivo === name);

                            var idArchivo = String(archivo?.idArchivo);
                            if (idArchivo == 0) {
                              alert('Atención! El archivo ya está firmado.');
                              if (id > 0) {
                                this.files[id].adj_firmado = true;
                                this.$set(this.files, id, this.files[id]);
                              }
                              this.show = false;
                            } else if (idArchivo > 0) {
                              this.signProcess(access_token, idUsuarioC, idArchivo, id, temp, adj_nmbre);
                            } else {
                              alert('¡Atención! No se pudo realizar la firma del documento. \n' + archivo.observacion + '. \nSi el archivo está pendiente de firma en la plataforma de Firmese, elimínelo. \nTambién puede cambiar el archivo para poder firmarlo.');
                              this.show = false;
                            }
                          } else {
                            alert('¡Atención! No se pudo realizar la firma del documento. \n' + archivo.observacion + '. \nSi el archivo está pendiente de firma en la plataforma de Firmese, elimínelo. \nTambién puede cambiar el archivo para poder firmarlo.');
                            this.show = false;
                          }
                        }).catch(err => {
                          alert('Error al subir el archivo al servicio de firmas. ' + JSON.stringify(err));
                          this.show = false;
                        });
                      }
                    })
                    .catch(err => {
                      alert('Error al descargar el archivo.');
                      this.show = false;
                    });
                
                } else {
                  alert('No tiene firmas disponibles, consulte con el administrador del sistema.');
                  this.show = false;
                }
              }).catch(err => {
                alert('Error al conectar con el servicio de firmas. Disponibilidad.');
                this.show = false;
              });
          }).catch(err => {
            alert('Error al conectar con el servicio de firmas. Autenticación. ' + err.data.test);
            this.show = false;
          });
      },
      async signProcess(access_token, idUsuarioC, idArchivo, id, temp, adj_nmbre) {
        var data = [{
          'idArchivo': idArchivo,
          'idUsuario': idUsuarioC
        }];
        var config = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token } };
        let uri = 'https://www.firme.se:9999/firma/api/v1/firma/manager/fimar-multiple';
        this.axios.post(uri, data, config)
          .then((response) => {
            if (response.data.codigo == '000') {
              /*
              var p = {};
              var config = { headers: { 'Authorization': 'Bearer ' + access_token } };
              */
              this.codigoOtp = {};
              this.codigoOtp.char1 = '';
              this.codigoOtp.char2 = '';
              this.codigoOtp.char3 = '';
              this.codigoOtp.char4 = '';
              this.codigoOtp.char5 = '';
              this.codigoOtp.char6 = '';
              this.codigoOtp.access_token = access_token;
              this.codigoOtp.idUsuarioC = idUsuarioC;
              this.codigoOtp.idArchivo = idArchivo;
              this.codigoOtp.id = id;
              this.codigoOtp.email = false;
              this.codigoOtp.temp = temp;
              this.codigoOtp.adj_nmbre = adj_nmbre;

              $('#codigoOtpFirmeseModal').modal('show');
              this.show = false;
            } else {
              alert('Error al firmar el documento. ' + response.data.mensaje);
              this.show = false;
            }
          }).catch((err) => {
            alert('Error al solicitar el código OTP para firmar el documento. ' + JSON.stringify(err));
            this.show = false;
          });
      },
      cambiarMedioOtpFirmese(codigo) {
        $('#codigoOtpFirmeseModal').modal('hide');
        this.show = true;
        let uri = 'https://www.firme.se:9999/firma/api/v1/validacion/registro/enviar-otp-email?id=' + this.codigoOtp.idUsuarioC + '&tipo=single';
        this.axios.post(uri, {})
          .then((response) => {
            if (response.data.codigo == '000') {
              this.codigoOtp = {};
              this.codigoOtp.char1 = '';
              this.codigoOtp.char2 = '';
              this.codigoOtp.char3 = '';
              this.codigoOtp.char4 = '';
              this.codigoOtp.char5 = '';
              this.codigoOtp.char6 = '';
              this.codigoOtp.access_token = codigo.access_token;
              this.codigoOtp.idUsuarioC = codigo.idUsuarioC;
              this.codigoOtp.idArchivo = codigo.idArchivo;
              this.codigoOtp.id = codigo.id;
              this.codigoOtp.email = true;
              this.codigoOtp.temp = codigo.temp;
              this.codigoOtp.adj_nmbre = codigo.adj_nmbre;

              $('#codigoOtpFirmeseModal').modal('show');
              this.show = false;
            } else {
              alert('Error al enviar el código de verificación al correo electrónico. ' + response.data.mensaje);
              this.show = false;
            }
          }).catch((err) => {
            alert('Error al solicitar el código OTP al correo electrónico para firmar el documento. ' + JSON.stringify(err));
            this.show = false;
          });
      },
      verificarCodigoOtpFirmese(obj) {
        var csms = obj.char1 + obj.char2 + obj.char3 + obj.char4 + obj.char5 + obj.char6;
        var access_token = obj.access_token;
        var idUsuarioC = obj.idUsuarioC;
        var idArchivo = obj.idArchivo;
        var id = obj.id;
        this.show = true;

        if (csms != '') {
          var p = {};
          var config = { headers: { 'Authorization': 'Bearer ' + access_token } };
          let uri = 'https://www.firme.se:9999/firma/api/v1/firma/manager/validarsms-multiple?csms=' + csms + '&usms=' + idUsuarioC;
          this.axios.post(uri, p, config)
            .then((response) => {
              if (response.data.codigo == '000') {
                //* Descargar archivo
                var p = {};
                let uri = 'https://www.firme.se:9999/firma/api/v1/validacion/registro/downloadB64/' + idArchivo;
                this.axios.post(uri, p)
                  .then(async (response) => {
                    const blobPdfFromBase64String = base64String => {
                      const byteArray = Uint8Array.from(
                        atob(base64String)
                          .split('')
                          .map(char => char.charCodeAt(0))
                      );
                      return new Blob([byteArray], { type: 'application/pdf' });
                    };

                    var fileBlob = blobPdfFromBase64String(response.data.data);

                    var path;
                    if (obj.temp) {
                      path = String(this.$store.state.company) + '/temp/' + this.$store.state.user + '/' + this.timestamp;
                    } else {
                      path = String(this.$store.state.company) + '/sdocs/' + Math.trunc(parseInt(this.item._id.substring(0, 8), 16) / 1000000).toString() + '/' + this.item._id;
                    }

                    //var name = obj.adj_nmbre.substring(obj.adj_nmbre.lastIndexOf('/') + 1);
                    var name = obj.adj_nmbre;

                    //* Chunks
                    const chunkSize = 1024 * 1024;

                    const uploadProgress = (file, id, start) => (progressEvent) => {
                      this.files[id].percentage = parseInt(Math.round((start + chunkSize * progressEvent.loaded / progressEvent.total) * 100 / file.size));
                      this.$set(this.files, id, this.files[id]);
                    }

                    const sendChunk = async (file, id, chunkIndex, totalChunk) => {
                      const start = chunkIndex * chunkSize;
                      const end = (chunkIndex + 1) * chunkSize;

                      const formData = new FormData();
                      const blobSlice = file.slice(start, end);
                      var name = file.name;

                      formData.append('file', blobSlice, encodeURIComponent(name));
                      formData.append('index', id);
                      formData.append('chunkIndex', chunkIndex);
                      formData.append('totalChunk', totalChunk);
                      var params = {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                      };

                      if (id > 0) {
                        params.onUploadProgress = uploadProgress(file, id, start).bind(this);
                      }

                      return await this.axios.post('/api/upload/' + path,
                        formData,
                        params
                      );                  
                    }

                    var file = fileBlob;
                    file.name = name;

                    const totalChunk = Math.ceil(file.size/chunkSize);

                    for (let chunkIndex = 0; chunkIndex < totalChunk; chunkIndex++) {
                      try {
                        var response = await sendChunk(file, id, chunkIndex, totalChunk);
                        if (response) {
                          const last = (chunkIndex == totalChunk - 1);
                          if (last) {
                            let { index, pages, size } = await response.data;
                            if (id > 0) {
                              this.files[id]['uploaded'] = '0';
                              //this.files[m]['temp'] = true;
                              this.files[id]['adj_firmado'] = true;
                              this.files[id]['adj_pages'] = pages
                              this.files[id]['adj_size'] = size
                              this.$set(this.files, id, this.files[id]);
                            }

                            alert('El documento ha sido firmado satisfactoriamente.');
                            this.show = false;
                          }
                        }
                                    
                      } catch(err) {
                        if (obj.temp) {
                          let { index } = await err.data;
                          if (id > 0) {
                            this.files[id]['uploaded'] = '2';
                            this.$set(this.files, id, this.files[id]);
                          }
                        }

                        alert('Error al cargar el documento a Siged. ' + JSON.stringify(err));
                        this.show = false;
                      }
                    }                

                  }).catch((err) => {
                    alert('Error al descargar el documento. ' + JSON.stringify(err));
                    this.show = false;
                  });
              } else {
                alert('Error al firmar el documento.');
                this.show = false;
              }
            }).catch((err) => {
              if (err?.response?.data?.codigo == '001') {
                alert('Error al firmar el documento. ' + JSON.stringify(err?.response?.data?.mensaje));
              }

              this.show = false;
            });
        } else {
          alert('No se ha digitado el código de verificación.');
          this.show = false;
        }
      },
      async viewEmail(cmp_id, _id, email){
        try {
          this.emailFile = {};
          this.modalMsg = 'Consultando el mensaje de correo electrónico.';
          this.show = true;

          if (email.ema_raw) {
            let uri = '/emails/parse';
            let response = await this.axios.post(uri, { cmp_id, _id, email });
  
            this.emailFile = { ...response.data };
          } else {
            this.emailFile = {
              subject: email?.ema_mensaje?.subject,
              date: email?.ema_fecha,
              from: email?.ema_mensaje?.from,
              to: email?.ema_mensaje?.to,
              cc: email?.ema_mensaje?.cc,
              attachments: this.emailAttachments(email?.ema_mensaje?.attachments),
              html: email?.ema_mensaje?.html
            };
          }

          this.emailFile.cmp_id = cmp_id;
          this.emailFile._id = _id;
          this.emailFile.ema_message_id = email.ema_message_id;
          this.emailFile.logo = await this.getImgBase64();

          $('#emailModal').modal('show');    
          this.show = false;      
        } catch(err) {
          this.message = 'Error al mostrar el email.';
          this.show = false;      
        }
      },
      viewTiff() {
        //alert('viewTiff');
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        xhr.open('GET', "./../images/002.tif");
        xhr.onload = function (e) {
          var tiff = new Tiff({ buffer: xhr.response });
          var canvas = tiff.toCanvas();
          document.body.append(canvas);
        };
        xhr.send();
      },
      eliminarPqrs() {
        this.item.tpq_id = undefined;
      },
      eliminarEntidad() {
        this.item.ent_id = undefined;
      },
      percentageValue(p) {
        return 'width:' + p + '%';
      },
      async fetchEntidadItem(ent_id) {
        let uri = '/entidades/edit/' + String(ent_id);
        this.axios.get(uri).then((response) => {
          this.entidades = [];
          var i = response.data;
          this.entidades.push({ id: String(i._id), text: i.ent_nmbre + ' (' + i.ent_numero + ')' });
          $('#ent_id').append('<option value="' + i._id + '">' + i.ent_nmbre + ' (' + i.ent_numero + ')' + '</option>');
          $("#ent_id").trigger({
            type: 'select2.select',
            params: {
              data: { id: i._id }
            }
          });
        });
      },
      async fetchCiudadItem(ciud_id) {
        let uri = '/ciudades/edit/' + String(ciud_id);
        this.axios.get(uri).then((response) => {
          var i = response.data;
          $('#ciud_id').append('<option value="' + i._id + '">' + i.ciud_desc_mcp + ' (' + i.ciud_desc_dep + ')' + '</option>');
          $("#ciud_id").trigger({
            type: 'select2.select',
            params: {
              data: { id: i._id }
            }
          });
        });
      },
      fetchEntidad() {
        let uri = '/entidades/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function (a, b) {
            if (a.ent_nmbre > b.ent_nmbre) { return 1; };
            if (a.ent_nmbre < b.ent_nmbre) { return -1; };
            return 0;
          });
          this.entidades = list.map(i => ({ id: String(i._id), text: i.ent_nmbre + ' (' + i.ent_numero + ')' }));
        });
      },
      async fetchTposolic() {
        let uri = '/tposolics/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tposolics = response.data;
        });
      },
      async fetchMedioresp() {
        let uri = '/medioresps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.medioresps = response.data;
        });
      },
      async fetchProcesos() {
        let uri = '/procesos/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.procesos = response.data;
        });
      },
      async getNow() {
        let uri = '/api/now';
        this.axios.post(uri).then((response) => {
          var di = response.data.now;
          this.now = moment(di).format('DD/MMM/YYYY hh:mm:ss A');
          var interval = 1000;
          var i = 0;
          var x = setInterval(() => {
            var nd = new Date(di);
            i += interval
            nd.setTime(nd.getTime() + i);
            this.now = moment(nd).format('DD/MMM/YYYY hh:mm:ss A');
          }, interval);
        });
      },
      emailButton() {
        this.close = false;
        this.sendEmailButton = true;
        this.esReclamacion = false;
        this.esPqrs = false;
        this.saveItem();
      },
      changeClassMouseOver(id) {
        //debugger;
        document.getElementById(id).className = 'm-2 p-2 border border-info rounded shadow bg-white';
      },
      changeClassMouseOut(id) {
        document.getElementById(id).className = 'm-2 p-2 border border-info rounded';
      },
      emailEstado(estado) {
        let text = ""
        switch (estado) {
          case 'send':
            text = 'Enviado';
            break;
          case 'delivery':
          case 'delivered':
            text = 'Entregado';
            break;
          case 'open':
          case 'read':
            text = 'Abierto';
            break;
          case 'bounce':
            text = 'Rebote';
            break;
          case 'complaint':
            text = 'Denuncia';
            break;
          case 'renderingFailure':
            text = 'Fallo de representación';
            break;
        }

        return text
      },
      email_class_estado(estado) {
        let text = ""
        switch (estado) {
          case 'send':
            text = 'text-warning';
            break;
          case 'delivery':
          case 'delivered':
            text = "text-primary"
            break;
          case 'open':
          case 'read':
            text = 'text-success';
            break;
          case 'bounce':
            text = 'text-danger';
            break;
        }

        return `${text} small`
      },
      emial_response(data) {
        let item = data.ema_envelope

        if (!item) return ""

        if (data.ema_estado == "bounce") {
          let count = 0
          let recipients = ""

          for (const record of item.bouncedRecipients) {
            if (count > 0) recipients += `, ${record.emailAddress}`
            else recipients += record.emailAddress
            count++
          }

          return " - " + recipients
        }

        if (!item?.recipients) return ""

        return " - " + item.recipients.join(", ")
      },
      setTpopqrs(e) {
        if (!this.gettingItem) {
          var tpq = e.target.value;

          for (var i = 0; i < this.tpopqrs.length; i++) {
            if (tpq == this.tpopqrs[i]._id) {
              if (this.tpopqrs[i].tpq_tres) {
                this.item.dcm_tres = this.tpopqrs[i].tpq_tres;
              }
              i = this.tpopqrs.length;
            }
          }
        }
      },
      reclamacion() {
        this.close = true;
        this.sendEmailButton = false;
        this.esReclamacion = true;
        this.saveItem();
      },
      formatoPqrs() {
        if (!this.item.tpq_id) {
          alert('Para agregar los datos de Pqrs por favor seleccionar el Tipo de solicitud.');
          this.$refs.tpq_id.focus();
          return;
        }

        this.close = true;
        this.sendEmailButton = false;
        this.esPqrs = true;
        this.saveItem();
      },
      validarRespuesta(e) {
        if (!this.add) {
          var msg = '';
          const dcm_rspsta = document.querySelector('#dcm_rspsta');
          console.log(JSON.stringify(dcm_rspsta.checked));
          if (dcm_rspsta.checked) {
            msg = '¿Está seguro que desea activar esta casilla? Al hacerlo el documento se mostrará pendiente de respuesta hasta que se genere la respectiva respuesta.';
          } else {
            msg = '¿Está seguro que desea desactivar esta casilla? Al hacerlo el documento ya no será controlado para generar una respuesta.';
          }
          var response = confirm(msg);
          if (!response) {
            e.preventDefault();
          }
        }
      },

      newTipodoc() {
        this.tipodoc = {};
        this.addTd = true;
      },
      addTipodoc(i) {
        if (this.addTd) {
          this.item.hojacontrol.push({
            hjc_nmro: i.hjc_nmro,
            hjc_fecha: i.hjc_fecha,
            hjc_descripcion_doc: i.hjc_descripcion_doc,
            hjc_folio_ini: i.hjc_folio_ini,
            hjc_folio_fin: i.hjc_folio_fin,
            hjc_area_responsable: i.hjc_area_responsable,
            temp: true
          });
        } else {
          this.item.hojacontrol[this.editTd] = {
            _id: i._id,
            hjc_nmro: i.hjc_nmro,
            hjc_fecha: i.hjc_fecha,
            hjc_descripcion_doc: i.hjc_descripcion_doc,
            hjc_folio_ini: i.hjc_folio_ini,
            hjc_folio_fin: i.hjc_folio_fin,
            hjc_area_responsable: i.hjc_area_responsable
          };
        }
        this.tipodoc = {};
        this.addTd = true;
        this.showModal = false;
      },
      editTipodoc(i) {
        this.tipodoc = {
          _id: this.item.hojacontrol[i]._id,
          hjc_nmro: this.item.hojacontrol[i].hjc_nmro,
          hjc_fecha: this.item.hojacontrol[i].hjc_fecha.toString().substring(0, 10),
          hjc_descripcion_doc: this.item.hojacontrol[i].hjc_descripcion_doc,
          hjc_folio_ini: this.item.hojacontrol[i].hjc_folio_ini,
          hjc_folio_fin: this.item.hojacontrol[i].hjc_folio_fin,
          hjc_area_responsable: this.item.hojacontrol[i].hjc_area_responsable
        };
        this.addTd = false;
        this.editTd = i;
        //this.indicesButtonText = 'Guardar cambios';
      },
      deleteTipodoc(i) {
        const response = confirm('Está seguro que desea eliminar?');
        if (response) {
          if (this.item.hojacontrol[i].temp) {
            this.item.hojacontrol.splice(i, 1);
          } else {
            this.item.hojacontrol[i].delete = true;
            this.$set(this.item.hojacontrol, i, this.item.hojacontrol[i]);
          }
        }
      },
      addDeletedTipodoc(i) {
        //this.item.hojacontrol.splice(index,1);
        this.item.hojacontrol[i].delete = false;
        this.$set(this.item.hojacontrol, i, this.item.hojacontrol[i]);
      },

      async generateControlSheet_v2() {
        let dependencia = this.dependencias.find(i => i._id === this.item.dep_id);
        let serie = this.series.find(i => i._id === this.item.ser_id);

        var legajo = {
          dep_cdgo: dependencia.dep_cdgo,
          dep_nmbre: dependencia.dep_nmbre,
          ser_cdgo: serie.ser_cdgo,
          ser_nmbre: serie.ser_nmbre,
          dcm_asunto: this.item.dcm_asunto,
          dcm_nflios: this.item.dcm_nflios,
          dcm_tomo: this.item.dcm_tomo,
          dcm_fchaini: formatDateUtc(this.item.dcm_fchaini)
        };

        let hojacontrol = [...this.item.hojacontrol || []];

        let logo = await this.getImgBase64()

        const dataFormat = {
          header: { ...legajo },
          body: {
            ...hojacontrol.map(e => {
              if (e.hjc_fecha) {
                e.hjc_fecha = `${e.hjc_fecha.toString().substring(0, 10)}`
              }
              return e
            })
          },
          logo
        }

        let datos = {
          tipo: 'pdf',
          datos: dataFormat,
          formato: "control_sheet_pdf",
          order_data: false
        };

        let new_impresion = new this.gb.Impresion(datos);

        new_impresion.init(
          (res) => {
            console.log(res);
          },
          (err) => {
            console.log(err);
          }
        );
      },

      async getImgBase64() {
        let filename = this.$store.state.companydata.cmp_logo
        let path = './../repos/' + String(this.$store.state.company) + '/logos';

        let logo = await this.axios({
          url: '/api/files/download',
          method: 'POST',
          data: { 'path': path, 'filename': filename },
          responseType: 'arraybuffer'
        })

        return 'data:image/png;base64,' + Buffer.from(logo.data, 'binary').toString('base64')
      },

      validarUsuario() {
        var value = false;
        if (this.allowModify) {
          value = true;
        } else {
          var n = this.item.seguimientos?.length || 0;
          if (n > 0) {
            if (String(this.$store.state.user) == String(this.item.seguimientos[n - 1].ParaUsuarioId)) {
              value = true;
            }
          }
        }

        return value;
      },

      syncEmails() {
        var id = this.item._id;
        this.modalMsg = 'Actualizando los eventos del correo electrónico.';
        this.show = true;
        let uri = '/docs/sync_emails';
        this.axios.post(uri, { id })
          .then(response => {
            this.item.emails = response.data.emails;
            this.show = false;
            this.message = 'Eventos actualizados.';
          })
          .catch(err => {
            this.message = 'El sistema no ha podido sincronizar el estado de los correos electrónicos en este momento.';
            alert(this.message);
            this.show = false;
          });
      },
      userSendEmail(id) {
        const u = this.usuarios.find(u => u._id == id);
        const name = u.usu_nmbre + ' ' + u.usu_aplldo;
        return name;
      },
      toGroup(group) {
        this.$vuetify.goTo(group)
      },
      emailAttachments(a){
        return a.map(i => {
          return i.path.replace(/^.*[\\\/]/, '');
        });      
      },
      showModalForm(value, msg) {
        this.modalMsg = msg;
        this.show = value;
      }
      /** END METHODS */
    },
    watch: {
      /*
      'item.tcr_id': function(){
        for (var i = 0; i < this.tpocors.length; i++){
          if (this.item.tcr_id == this.tpocors[i].tcr_id){
            //alert(this.tpocors[i].tcr_corr);
            this.isMail = this.tpocors[i].tcr_corr;
            if (this.isMail){
              for (var j = 0; j < this.tpoarchs.length; j++){
                if (this.tpoarchs[j].tpa_cdgo == '1'){
                  this.item.tpa_id = String(this.tpoarchs[j]._id);
                  j = this.tpoarchs.length;
                }
              }
            }
            return;
          }
        }
      }
      */

      async searchEmailAddresses (val) {
        if (val == null || String(val)?.length < 3) {
          this.emailAddresses = [];
          return;
        }

        let uri = "/entidades/search-email-addresses";
        let params = {
          cmp_id: this.$store.state.company,
          search: val          
        };
        this.axios.post(uri, params)
        .then(response => {
          console.log(JSON.stringify(response.data))
          this.emailAddresses = response.data.map(i => {
            return {
              text: i.ent_email
            }
          });
        });
      },

      selectedEmailAddresses(val, prev) {
        if (val.length === prev.length) return

        this.selectedEmailAddresses = val.map(v => {
          if (typeof v === 'string') {
            v = {
              text: v
            }

            this.emailAddresses.push(v)

            this.nonce++
          }

          return v
        });

        if (this.selectedEmailAddresses) {
          this.item.dcm_email = this.selectedEmailAddresses.map(i => i.text).join(',');
        }
        
      },
      
    }
  }
</script>
<style src="select2/dist/css/select2.css"></style>
<style>
  .warning {
    color: #ff0000;
  }

  div.file-listing {
    width: 200px;
  }

  span.remove-file {
    color: red;
    cursor: pointer;
    float: right;
  }

  progress::-webkit-progress-value {
    color: green;
  }

  progress::-moz-progress-bar {
    color: green;
  }

  progress::-moz-progress-bar {
    color: green;
  }

  .select-control {
    border: 1px solid #d2caca;
    font-size: 16px;
    padding: 5px;
    height: 35px;
    width: 450px;
  }

  .select2-container .select2-selection--single {
    box-sizing: border-box;
    border: 1px solid #ced4da;
    cursor: pointer;
    display: block;
    height: 40px;
    user-select: none;
    -webkit-user-select: none;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 40px;
  }

  .select2-dropdown {
    z-index: 1040;
  }

  .mensaje_pqrs {
    background: #e9ecef;
    border: 1px solid #ced4da;
    white-space: pre-wrap;
    border-radius: .25rem;
    padding: .375rem .75rem;
  }

  .tachado {
    text-decoration: line-through;
    color: #ff0000;
  }

  .mensaje_editor .ql-editor {
    height: 200px
  }

  ul.timeline {
    list-style-type: none;
    position: relative;
  }

  ul.timeline:before {
    content: ' ';
    background: green;
    display: inline-block;
    position: absolute;
    left: 15px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }

  ul.timeline > li {
    margin: 0 0;
    padding-left: 5px;
  }

  ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid green;
    left: 10px;
    width: 12px;
    height: 12px;
    z-index: 400;
    margin-top: 6px;
  }

  .ult_seg {
    background-color: skyblue;
  }
</style>