<template>
    <div v-if="direccion == 'O' && doc._id" class="control-section folder-upload">
        <div class="sample-container">
            <ejs-filemanager id="filemanager" ref="filemanagerInstance" view="Details" :allowDragAndDrop="allowModify" :ajaxSettings="ajaxSettings" :uploadSettings="uploadSettings"
                locale='es' :created='onCreated' rootAliasName="Archivos" :menuClick="menuClick"
                :toolbarSettings="toolbarSettings" :toolbarClick="toolbarClick" :fileOpen="fileOpen">
            </ejs-filemanager>
        </div>
        <viewpdf :pdfFile="pdfFile" @view-file-system-provider="viewFile" />
    </div>
</template>

<script>

import { FileManagerComponent, NavigationPane, Toolbar, DetailsView } from "@syncfusion/ej2-vue-filemanager";
import { DropDownButton } from "@syncfusion/ej2-vue-splitbuttons";
import ViewPdf from './ViewPdf.vue';

export default {
    props: {
        doc: {
            type: Object,
            default: {
                _id: null
            }
        },
        direccion: {
            type: String,
            default: null
        },
        allowModify: {
          type: Boolean,
          default: false
        }
    },
    components: {
        'ejs-filemanager': FileManagerComponent,
        'viewpdf': ViewPdf,
    },
    provide: {
        filemanager: [NavigationPane, DetailsView, Toolbar]
    },
    data() {
        return {
            ajaxSettings:
            {
                url: "/filesystemprovider",
                getImageUrl: '/filesystemprovider/GetImage',
                uploadUrl: '/filesystemprovider/Upload',
                downloadUrl: '/filesystemprovider/Download'
            },
            uploadSettings: { 
              chunkSize: 1048576,
              maxFileSize: 2e+9 
            },
            items: [{ text: "Folder" }, { text: "Files" }],
            pdfFile: {
                url: null,
                adj_nmbre: '',
                systemFile: true
            },
            file: {
                path: null,
                name: null
            },
            toolbarSettings: {
                items: [
                    'NewFolder',
                    'Upload',
                    'Cut',
                    'Copy',
                    'Paste',
                    'Delete',
                    'Download',
                    'Rename',
                    'SortBy',
                    'Refresh',
                    'Selection',
                    'View',
                    'Details',
                ],
                visible: true
            }
        }
    },
    watch: {
        doc: function (val) {
            if (val._id) {
                let query = `_id=${val._id}&cmp_id=${val.cmp_id}&usu_id=${val.usu_id}`

                this.ajaxSettings = {
                    url: `/filesystemprovider?${query}`,
                    getImageUrl: `/filesystemprovider/GetImage?${query}`,
                    uploadUrl: `/filesystemprovider/Upload?${query}`,
                    downloadUrl: `/filesystemprovider/Download?${query}`
                }

                if (['E', 'O'].includes(this.direccion)) {
                  this.toolbarSettings.items.push("Firmar documento")
                  this.toolbarSettings.items.push("Crear desde plantilla")
                }
            }
        },
        allowModify: function(val) {
          if (val) { 
            this.$refs.filemanagerInstance?.enableToolbarItems(["NewFolder", "Upload", "Cut", "Copy", "Paste", "Delete", "Download", "Rename"]);
          } else {
            this.$refs.filemanagerInstance?.disableToolbarItems(["NewFolder", "Upload", "Cut", "Copy", "Paste", "Delete", "Download", "Rename"]);
          }
        }
    },
    methods: {
        onSelect: function (args) {
            var fileObj = this.$refs.filemanagerInstance;
            console.log("text: ", args.item.text)

            if (args.item.text === "Folder") {
                fileObj.ej2Instance.uploadSettings.directoryUpload = true;
            } else {
                fileObj.ej2Instance.uploadSettings.directoryUpload = false;
            }
            setTimeout(function () {
                var uploadBtn = document.querySelector(".e-file-select-wrap button");
                uploadBtn.click();
            }, 100);
        },
        uploadClick: function (args) {
            args.stopPropagation();
        },
        onCreated: function (args) {
            if (this.allowModify) { 
              this.$refs.filemanagerInstance?.enableToolbarItems(["NewFolder", "Upload", "Cut", "Copy", "Paste", "Delete", "Download", "Rename"]);
            } else {
              this.$refs.filemanagerInstance?.disableToolbarItems(["NewFolder", "Upload", "Cut", "Copy", "Paste", "Delete", "Download", "Rename"]);
            }
                      
            var customBtn = document.getElementById("filemanager_tb_upload");
            customBtn.onclick = (e) => {
                e.stopPropagation();
            };

            var items = [{ text: "Carpeta" }, { text: "Archivo" }];
            var drpDownBtn = new DropDownButton({
                items,
                select: (args) => {
                    var fileObj = document.getElementById("filemanager").ej2_instances[0];
                    fileObj.uploadSettings = { 
                      chunkSize: 1048576, 
                      maxFileSize: 2e+9 
                    }
                    
                    if (args.item.text === "Carpeta") {
                        fileObj.uploadSettings.directoryUpload = true;
                    } else {
                        fileObj.uploadSettings.directoryUpload = false;
                    }
                    setTimeout(function () {
                        var uploadBtn = document.querySelector(".e-file-select-wrap button");
                        uploadBtn.click();
                    }, 100);
                },

            }, "#filemanager_tb_upload"
            );
        },

        fileOpen: function (args) {
            if (args.fileDetails.type == ".pdf") {
                this.onFileOpen(args.fileDetails)
            }
        },

        menuClick: async function (args) {
            const { item, fileDetails } = args

            if (item.text == "Abrir" && fileDetails.length == 1 && fileDetails[0].type == ".pdf") {
                this.onFileOpen(fileDetails[0])
            }
        },

        onFileOpen: async function (fileDetails) {
          try {
            this.$emit('showModalForm', true, 'Por favor espere mientras se muestra el archivo..')

            this.pdfFile = {};
            let val = this.doc
        
            this.file = {
                name: fileDetails.name,
                path: fileDetails.filterPath
            }

            let { name, path } = this.file

            var data = {       
                _id: val._id,
                cmp_id: val.cmp_id,
                path: path,
                name: encodeURIComponent(name)
            }

            let base_url = process.env.NODE_ENV === "production" ? "" : "http://localhost:4000"

            const response = await this.axios({
              url: `${base_url}/filesystemprovider/pass`,
              method: 'POST',
              data,
              headers: {
                'Authorization': sessionStorage.getItem('jwtToken')
              },
            });

            const token = response?.data?.tk;
            var url = `${base_url}/filesystemprovider/view-file?tk=${encodeURIComponent(token)}`;

            this.pdfFile = {
              url: url,
              adj_nmbre: name
            };

            $('#pdfModal').modal('show');
            this.$emit('showModalForm', false);
          } catch (err) {
            this.$emit('showModalForm', false);
          }            
        },

        // File Manager's file Drag start event function
        onFileDragStart: function(args){
            console.log("File Drag Start");
        },
        // File Manager's file Drag stop event function
        onFileDragStop: function(args){
            console.log("File Drag Stop");
        },
        // File Manager's file Dragging event function
        onFileDragging: function(args){
            console.log("File Dragging");
        },
        // File Manager's file Dropped event function
        onFileDropped: function(args){
            console.log("File Dropped");
        },

        async viewFile() {
          try {
            let val = this.doc
            let { name, path } = this.file

            let query = `_id=${val._id}&cmp_id=${val.cmp_id}&path=${path}&name=${encodeURIComponent(name)}`
            let base_url = process.env.NODE_ENV === "production" ? "" : "http://localhost:4000"

            this.$emit('showModalForm', true, 'Por favor espere mientras se muestra el archivo..')

            /*
            const response = await this.axios({
              url: `${base_url}/filesystemprovider/view-file?${query}`,
              method: 'GET',
              headers: {
                'Authorization': sessionStorage.getItem('jwtToken')
              },
              responseType: 'blob'
            });

            const urlObject = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
            */

            var url = `${base_url}/filesystemprovider/view-file?${query}`;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            this.$emit('showModalForm', false);
          } catch(err) {
            this.$emit('showModalForm', false);
          }
        },

        async onSignPdf (fileDetails) {
          try {
            let details = fileDetails[0]

            let filterPath = details?.filterPath || "//"
            console.log(filterPath)
            let name = details?.name || ""
            console.log(name)

            let path = `${name}`
            if (filterPath != "//") {
              path = `${filterPath}${name}`
            }

            if (path){
              this.$emit('signPdf', path, false, true, -1)
            }

          } catch(err){
            alert('Error al firmar el archivo.');
          }
        },

        async onCreatePdf(fileDetails) {
          try {
            let details = fileDetails

            console.log(JSON.stringify(details))
            debugger

            if (details.length > 0) {
              var path = "//";

              let { isFile, name, filterPath } = details[0];
              if (!isFile) {
                if (filterPath != "") {
                  path = `${filterPath}${name}`;
                }
              } else {
                path = `${filterPath}`;
              }

              this.$emit('newFileTemplate', path)
            } else {
              alert('Seleccione la carpeta donde se creará el archivo.');
            }

          } catch(err){
            alert('Error al firmar el archivo.');
          }
        },

        toolbarClick: function (args) {
            console.log("args.item.text: ", args.item.text)
            if (args.item.text == "Crear desde plantilla") {
              this.onCreatePdf(args.fileDetails)
            } else if (args.item.text == "Firmar documento" && args?.fileDetails?.length == 1 && args?.fileDetails[0]?.type == ".pdf") {
              this.onSignPdf(args.fileDetails)
            }            
        }
    }
}

</script>